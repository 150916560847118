import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SENSOR_TYPE_CHOICES = [
    'Exor Occupancy',
    'Exor Daylight',
    'None Located',
    'None',
    'Integral Sensor',
];

const DRIVER_TYPE_CHOICES = [
    'Standard',
    '0-10V',
    'DALI',
    'Dimmable',
    'Central Battery',
];

const SWITCH_TYPE_CHOICES = [
    'On/Off',
    'Rocker',
    'Slide Dimmer',
    'Rotary Dimmer',
    'Control Panel',
    'None',
    'Pull Switch',
];

const MOUNTING_CHOICES = [
    'Suspended',
    'Surface',
    'Recessed',
    'Wall',
    'Trunking',
];

const HEIGHT_CHOICES = [
    '2.0', '2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7', '2.8', '2.9', '3.0', '3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9', '4.0', '4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7', '4.8', '4.9', '5.0', '5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7', '5.8', '5.9', '6.0', '6.1', '6.2', '6.3', '6.4', '6.5', '6.6', '6.7', '6.8', '6.9', '7.0', '7.1', '7.2', '7.3', '7.4', '7.5', '7.6', '7.7', '7.8', '7.9', '8.0', '8.1', '8.2', '8.3', '8.4', '8.5', '8.6', '8.7', '8.8', '8.9', '9.0', '9.1', '9.2', '9.3', '9.4', '9.5', '9.6', '9.7', '9.8', '9.9', '10.0', '10.1', '10.2', '10.3', '10.4', '10.5', '10.6', '10.7', '10.8', '10.9', '11.0', '11.1', '11.2', '11.3', '11.4', '11.5', '11.6', '11.7', '11.8', '11.9', '12.0', '12.1', '12.2', '12.3', '12.4', '12.5', '12.6', '12.7', '12.8', '12.9', '13.0', '13.1', '13.2', '13.3', '13.4', '13.5', '13.6', '13.7', '13.8', '13.9', '14.0', '14.1', '14.2', '14.3', '14.4', '14.5', '14.6', '14.7', '14.8', '14.9', '15.0',

]

const ENLIGHTED_SENSOR =[
    'KIT-SU-SE-D-IoT',
    'SU-5S-2W-H-IoT',
    'SU-5S-2W-HRW-IoT',
    'SU-5S-2W-LRW-IoT',
    'SU-5E-FL',
    'SU-5S-2W-H-FL',
    'SU-5S-2W-HRW-FL',
    'SU-5S-2W-LRW-FL',
]

function EditBuildingName() {
    const [existingData, setExistingData] = useState([]);
    const [proposedData, setProposedData] = useState([]);
    const [plsCodes, setPlsCodes] = useState('');
    const [existingFittings, setExistingFittings] = useState('');
    const [conecttedLoads, setConnectedLoads] = useState('');

    const [electricityPricePerKwh, setElectricityPricePerKwh] = useState(null);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [facilityArea, setFacilityArea] = useState(null);
    const [roomNumber, setRoomNumber] = useState('');
    const [sensor, setSensor] = useState(false);
    const [fractionOfOperatingHours, setFractionOfOperatingHours] = useState(0);
    const [effectiveRoomsRunningHours, setEffectiveRoomsRunningHours] = useState(0);
    const [plsCode, setPlsCode] = useState('');
    const [existingFitting, setExistingFitting] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [sensorType, setSensorType] = useState('');
    const [driverType, setDriverType] = useState('');
    const [operatingSwitchType, setOperatingSwitchType] = useState('');
    const [numberOfSwitches, setNumberOfSwitches] = useState(0);
    const [mounting, setMounting] = useState('');
    const [fittingHeightM, setFittingHeightM] = useState(0);
    const [ceilingHeightM, setCeilingHeightM] = useState(0);
    const [comments, setComments] = useState('');
    const [connectedLoadW, setConnectedLoadW] = useState(0);
    const [sensorBenefit, setSensorBenefit] = useState(0);
    const [totalConnectedLoadKw, setTotalConnectedLoadKw] = useState(0);
    const [annualUsageKwh, setAnnualUsageKwh] = useState(0);
    const [proposedPlsCode, setProposedPlsCode] = useState('');
    const [proposedProduct, setProposedProduct] = useState('');
    const [proposedQuantity, setProposedQuantity] = useState(0);
    const [proposedConnectedLoadW, setProposedConnectedLoadW] = useState(0);
    const [proposedSensorBenefit, setProposedSensorBenefit] = useState(0);
    const [totalMaxConnectedLoadKw, setTotalMaxConnectedLoadKw] = useState(0);
    const [proposedAnnualUsageKwh, setProposedAnnualUsageKwh] = useState(0);
    const [enlightedSensor, setEnlightedSensor] = useState('');
    const [enlightedPsu, setEnlightedPsu] = useState('');
    const [enlightedMounting, setEnlightedMounting] = useState('');
    const [enlightedCable, setEnlightedCable] = useState('');
    const [numberOfEnlightedSwitches, setNumberOfEnlightedSwitches] = useState(0);
    const [unitCost, setUnitCost] = useState(0);
    const [unitMargin, setUnitMargin] = useState(0);
    const [totalUnitInclMargin, setTotalUnitInclMargin] = useState(0);
    const [installCost, setInstallCost] = useState(0);
    const [installMargin, setInstallMargin] = useState(0);
    const [materialsCost, setMaterialsCost] = useState(0);
    const [materialsMargin, setMaterialsMargin] = useState(0);
    const [totalInstallInclMargin, setTotalInstallInclMargin] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [costOfSaleToPls, setCostOfSaleToPls] = useState(0);
    const [savings, setSavings] = useState(0);
    const [payback, setPayback] = useState(0);
    const [totalSavingKwh, setTotalSavingKwh] = useState(0);
    const [totalSavingPercentage, setTotalSavingPercentage] = useState(0);
    const [totalSavingInCurrency, setTotalSavingInCurrency] = useState(0);
    const [totalSavingInCo2e, setTotalSavingInCo2e] = useState(0);
    const { projectId, pk } = useParams();
    const navigate = useNavigate();
    //
    const [facilityAreas, setFacilityAreas] = useState([]);
    const [selectedFacilityArea, setSelectedFacilityArea] = useState('');
    const [roomTypes, setRoomTypes] = useState([]);
    const [selectedRoomTypes, setSelectedRoomTypes] = useState('');
    const [buildingInfo, setBuildingInfo] = useState([]);
    const [selectedBuildingInfo, setSelectedBuildingInfo] = useState('');
    const [error, setError] = useState(null);
    const [margin, setMargin] = useState([]);
    const [unitMarginNo, setUnitMarginNo] = useState('');
    const [installMarginNo, setInstallMarginNo] = useState('');
    const [materialsMarginNo, setMaterialsMarginNo] = useState('');
    

    useEffect(() => {
        const fetchMargin = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/margin/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setMargin(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching margins:', error);
                setError('Error fetching margins');
            }
        };

        fetchMargin();
    }, [projectId]);  

    useEffect(() => {
        if (Array.isArray(margin) && margin.length > 0) {
            setUnitMarginNo(margin[0].unit_margin);
            setInstallMarginNo(margin[0].install_margin);
            setMaterialsMarginNo(margin[0].materials_margin);
        }
    }, [margin]);

    //console.log('Margins:', unitMarginNo);

    useEffect(() => {
        const fetchBuildingData = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/building_names_id/${pk}`);
                console.log('Building data response:', response.data); // Log response data
                const buildingData = response.data;

                if (buildingData) {
                    setRoomNumber(buildingData.room_number);
                    setSensor(buildingData.sensor);
                    setFractionOfOperatingHours(buildingData.fraction_of_operating_hours);
                    setEffectiveRoomsRunningHours(buildingData.effective_rooms_running_hours);
                    setPlsCode(buildingData.pls_code);
                    setExistingFitting(buildingData.existing_fitting);
                    setQuantity(buildingData.quantity);
                    setSensorType(buildingData.sensor_type);
                    setDriverType(buildingData.driver_type);
                    setOperatingSwitchType(buildingData.operating_switch_type);
                    setNumberOfSwitches(buildingData.number_of_switches);
                    setMounting(buildingData.mounting);
                    setFittingHeightM(buildingData.fitting_height_m);
                    setCeilingHeightM(buildingData.ceiling_height_m);
                    setComments(buildingData.comments);
                    setConnectedLoadW(buildingData.connected_load_w);
                    setSensorBenefit(buildingData.sensor_benefit);
                    setTotalConnectedLoadKw(buildingData.total_connected_load_kw);
                    setAnnualUsageKwh(buildingData.annual_usage_kwh);
                    setProposedPlsCode(buildingData.proposed_pls_code);
                    setProposedProduct(buildingData.proposed_product);
                    setProposedQuantity(buildingData.proposed_quantity);
                    setProposedConnectedLoadW(buildingData.proposed_connected_load_w);
                    setProposedSensorBenefit(buildingData.proposed_sensor_benefit);
                    setTotalMaxConnectedLoadKw(buildingData.total_max_connected_load_kw);
                    setProposedAnnualUsageKwh(buildingData.proposed_annual_usage_kwh);
                    setEnlightedSensor(buildingData.enlighted_sensor);
                    setEnlightedPsu(buildingData.enlighted_psu);
                    setEnlightedMounting(buildingData.enlighted_mounting);
                    setEnlightedCable(buildingData.enlighted_cable);
                    setNumberOfEnlightedSwitches(buildingData.number_of_enlighted_switches);
                    setUnitCost(buildingData.unit_cost);
                    setUnitMargin(buildingData.unit_margin);
                    setTotalUnitInclMargin(buildingData.total_unit_incl_margin);
                    setInstallCost(buildingData.install_cost);
                    setInstallMargin(buildingData.install_margin);
                    setMaterialsCost(buildingData.materials_cost);
                    setMaterialsMargin(buildingData.materials_margin);
                    setTotalInstallInclMargin(buildingData.total_install_incl_margin);
                    setTotalCost(buildingData.total_cost);
                    setCostOfSaleToPls(buildingData.cost_of_sale_to_pls);
                    setSavings(buildingData.savings);
                    setPayback(buildingData.payback);
                    setTotalSavingKwh(buildingData.total_saving_kwh);
                    setTotalSavingPercentage(buildingData.total_saving_percentage);
                    setTotalSavingInCurrency(buildingData.total_saving_in_currency);
                    setTotalSavingInCo2e(buildingData.total_saving_in_co2e);



                } else {
                    throw new Error('Invalid building info data format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error.message);
                setErrors({ name: 'Error fetching building info' });
            }
        }

        fetchBuildingData();
    }, [projectId]);

    useEffect(() => {
        const fetchFacilityAreas = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/facility_areas/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setFacilityAreas(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };
        fetchFacilityAreas();
    }, [projectId]);

    useEffect(() => {
        const fetchRoomTypes = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/room_types/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setRoomTypes(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };
        fetchRoomTypes();
    }, [projectId]);

    useEffect(() => {
        const fetchBuildingInfo = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/building_infos/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setBuildingInfo(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };
        fetchBuildingInfo();
    }, [projectId]);
    const electricityPrices = buildingInfo.electricity_price_per_kwh;
    console.log('Electricity Price:', electricityPrices);
    const site_hours = (buildingInfo.days_per_week * buildingInfo.hours_per_day * buildingInfo.weeks_per_year);
    console.log('Site Hours:', site_hours);
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('Form data:', { pk, projectId, selectedFacilityArea, selectedBuildingInfo, selectedRoomTypes, roomNumber });


        try {
            const response = await axios.put(`https://light.quant.ac/api/building_names/${pk}/`,null , {params: {
                    building_name_id: pk,
                    facility_area_id: selectedFacilityArea,
                    room_type_id: selectedRoomTypes,
                    room_number: roomNumber,
                    building_info_id: selectedBuildingInfo,
                    sensor: sensor,
                    fraction_of_operating_hours: fractionOfOperatingHours,
                    effective_rooms_running_hours: effectiveRoomsRunningHours,
                    pls_code: plsCode,
                    existing_fitting: existingFitting,
                    quantity: quantity,
                    sensor_type: sensorType,
                    driver_type: driverType,
                    operating_switch_type: operatingSwitchType,
                    number_of_switches: numberOfSwitches,
                    mounting: mounting,
                    fitting_height_m: fittingHeightM,
                    ceiling_height_m: ceilingHeightM,
                    comments: comments,
                    connected_load_w: connectedLoadW,
                    sensor_benefit: sensorBenefit,
                    total_connected_load_kw: totalConnectedLoadKw,
                    annual_usage_kwh: annualUsageKwh,
                    proposed_pls_code: proposedPlsCode,
                    proposed_product: proposedProduct,
                    proposed_quantity: proposedQuantity,
                    proposed_connected_load_w: proposedConnectedLoadW,
                    proposed_sensor_benefit: proposedSensorBenefit,
                    total_max_connected_load_kw: totalMaxConnectedLoadKw,
                    proposed_annual_usage_kwh: proposedAnnualUsageKwh,
                    enlighted_sensor: enlightedSensor,
                    enlighted_psu: enlightedPsu,
                    enlighted_mounting: enlightedMounting,
                    enlighted_cable: enlightedCable,
                    number_of_enlighted_switches: numberOfEnlightedSwitches,
                    unit_cost: unitCost,
                    unit_margin: unitMargin,
                    total_unit_incl_margin: totalUnitInclMargin,
                    install_cost: installCost,
                    install_margin: installMargin,
                    materials_cost: materialsCost,
                    materials_margin: materialsMargin,
                    total_install_incl_margin: totalInstallInclMargin,
                    total_cost: totalCost,
                    cost_of_sale_to_pls: costOfSaleToPls,
                    savings: savings,
                    payback: payback,
                    
                
        }});

            if (response && response.data) {
                console.log(response.data); // Log the response data
                // Set success message or perform further actions
                setSuccess(true);
                navigate(`/project/${projectId}`);
            } else {
                console.error('Invalid response:', response);
                // Set appropriate error message
                setErrors({ name: 'Invalid response from the server' });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            // Set error message based on the response or general error
            setErrors({ name: error.response ? error.response.data.error : error.message });
        }
    };

    useEffect(() => {
        setEffectiveRoomsRunningHours( site_hours * fractionOfOperatingHours/100);
    }, [connectedLoadW, quantity]);

    useEffect(() => {
        const annualUsage =
            effectiveRoomsRunningHours *
            (1 - sensorBenefit / 100) *
            totalConnectedLoadKw;
        setAnnualUsageKwh(annualUsage);
    }, [sensorBenefit, totalConnectedLoadKw, effectiveRoomsRunningHours]);

    useEffect(() => {
        setTotalMaxConnectedLoadKw(proposedConnectedLoadW * proposedQuantity / 1000);
    }, [proposedConnectedLoadW, proposedQuantity]);

    useEffect(() => {
        const proposedAnnualUsageKwh =
            effectiveRoomsRunningHours *
            (1 - proposedSensorBenefit / 100) *
            totalMaxConnectedLoadKw;
        setProposedAnnualUsageKwh(proposedAnnualUsageKwh);
    }, [proposedSensorBenefit, totalMaxConnectedLoadKw, effectiveRoomsRunningHours]);

    useEffect(() => {
        setTotalUnitInclMargin(proposedQuantity * unitMargin);
    }, [proposedQuantity, unitMargin]);

    useEffect(() => {
        setTotalInstallInclMargin(proposedQuantity * (installMargin + materialsMargin));
    }, [installMargin, materialsMargin, proposedQuantity]);

    useEffect(() => {
        setTotalCost(totalInstallInclMargin + totalUnitInclMargin);
    }, [totalInstallInclMargin, totalUnitInclMargin]);

    useEffect(() => {
        setCostOfSaleToPls(proposedQuantity * (unitCost + installCost + materialsCost));
    }, [unitCost, proposedQuantity, installCost, materialsCost]);

    useEffect(() => {
        const savingsValue = ((annualUsageKwh - proposedAnnualUsageKwh) * electricityPrices).toFixed(1);
        setSavings(savingsValue);
    }, [annualUsageKwh, proposedAnnualUsageKwh]);

    useEffect(() => {
        const paybackValue = (totalCost / savings).toFixed(3);
        setPayback(paybackValue);
    }, [totalCost, savings]);

    useEffect(() => {
        setUnitMargin(unitCost/(1-unitMarginNo/100));
    }, [unitCost]);

    useEffect(() => {
        setInstallMargin(installCost/(1-installMarginNo/100));
    }, [installCost]);

    useEffect(() => { 
        setMaterialsMargin(materialsCost/(1-materialsMarginNo/100));
    }, [materialsCost]);
    

    const handlePlsCodeChange = (selectedCode) => {
        const selectedRow = existingData.find(row => row[0] === Number(selectedCode));
        if (selectedRow) {
            setExistingFitting(selectedRow[1]);
            setConnectedLoadW(selectedRow[2]);
        } else {
            setExistingFitting('');
            setConnectedLoadW(0);
        }
        setPlsCode(selectedCode);
    };

    useEffect(() => {
        const storedExistingData = localStorage.getItem("existingData");
        if (storedExistingData) {
            setExistingData(JSON.parse(storedExistingData));
        }

        const storedProposedData = localStorage.getItem("proposedData");
        if (storedProposedData) {
            setProposedData(JSON.parse(storedProposedData));
        }
    }, []);

    console.log('Existing data:', existingData);
    console.log('Proposed data:', proposedData);

    const handleProposedPlsCodeChange = (selectedCode) => {
        const selectedRow = proposedData.find(row => row[0] === Number(selectedCode));
        if (selectedRow) {
            setProposedProduct(selectedRow[1]);
            setProposedConnectedLoadW(selectedRow[2]);
            setUnitCost(selectedRow[3]);
        } else {
            setProposedProduct('');
            setProposedConnectedLoadW(0);
            setUnitCost(0);
        }
        setProposedPlsCode(selectedCode);
    };

    return (
        <div>
            <h1>Edit Building Name</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Facility Area:</label>
                    <select value={selectedFacilityArea} onChange={(e) => setSelectedFacilityArea(e.target.value)}>
                        <option value="">Select Facility Area</option>
                        {facilityAreas.map((facilityArea) => (
                            <option key={facilityArea.id} value={facilityArea.id}>{facilityArea.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Room Number:</label>
                    <input type="text" value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} />
                    {errors.roomNumber && <span>{errors.roomNumber}</span>}
                </div>
                <div>
                    <label>Room Type:</label>
                    <select value={selectedRoomTypes} onChange={(e) => setSelectedRoomTypes(e.target.value)}>
                        <option value="">Select Room Types</option>
                        {roomTypes.map((roomTypes) => (
                            <option key={roomTypes.id} value={roomTypes.id}>{roomTypes.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Building Info:</label>
                    <select value={selectedBuildingInfo} onChange={(e) => setSelectedBuildingInfo(e.target.value)}>
                        <option value="">Select Building Info</option>
                        <option key={buildingInfo.id} value={buildingInfo.id}>{buildingInfo.name}</option>
                    </select>
                </div>
                <div>
                    <label>Sensor:</label>
                    <input
                        type="checkbox"
                        checked={sensor}
                        onChange={(e) => setSensor(e.target.checked)}
                    />
                    {errors.sensor && <span>{errors.sensor}</span>}
                </div>
                <div>
                    <label>Fraction of Operating Hours:</label>
                    <input type="text" value={fractionOfOperatingHours} onChange={(e) => setFractionOfOperatingHours(e.target.value)} />
                    {errors.fractionOfOperatingHours && (
                        <span>{errors.fractionOfOperatingHours}</span>
                    )}
                </div>
                <div>
                    <label>Effective Rooms Running Hours:</label>
                    <input
                        type="text"
                        value={effectiveRoomsRunningHours}
                        onChange={(e) => setEffectiveRoomsRunningHours(e.target.value)}
                        readOnly
                    />
                    {errors.effectiveRoomsRunningHours && (
                        <span>{errors.effectiveRoomsRunningHours}</span>
                    )}
                </div>
                <div>
                    <label>PLS Code:</label>
                    <select value={plsCode} onChange={(e) => handlePlsCodeChange(e.target.value)}>
                        <option value="">Select PLS Code</option>
                        {existingData.slice(1).map((row, index) => (
                            <option key={index} value={row[0]}>{row[0]}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Existing Fitting:</label>
                    <input
                        type="text"
                        value={existingFitting}
                        onChange={(e) => setExistingFitting(e.target.value)}
                        readOnly
                    />
                    {errors.existingFitting && <span>{errors.existingFitting}</span>}
                </div>
                <div>
                    <label>Quantity:</label>
                    <input
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                    {errors.quantity && <span>{errors.quantity}</span>}
                </div>
                <div>
                    <label>Sensor Type:</label>
                    <select value={sensorType} onChange={(e) => setSensorType(e.target.value)}>
                        <option value="">Select Sensor Type</option>
                        {SENSOR_TYPE_CHOICES.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Driver Type:</label>
                    <select value={driverType} onChange={(e) => setDriverType(e.target.value)}>
                        <option value="">Select Driver Type</option>
                        {DRIVER_TYPE_CHOICES.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Operating Switch Type:</label>
                    <select value={operatingSwitchType} onChange={(e) => setOperatingSwitchType(e.target.value)}>
                        <option value="">Select Switch Type</option>
                        {SWITCH_TYPE_CHOICES.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Number of Switches:</label>
                    <input
                        type="text"
                        value={numberOfSwitches}
                        onChange={(e) => setNumberOfSwitches(e.target.value)}
                    />
                    {errors.numberOfSwitches && (
                        <span>{errors.numberOfSwitches}</span>
                    )}
                </div>
                <div>
                    <label>Mounting:</label>
                    <select value={mounting} onChange={(e) => setMounting(e.target.value)}>
                        <option value="">Select Mounting</option>
                        {MOUNTING_CHOICES.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Fitting Height (m):</label>
                    <select value={fittingHeightM} onChange={(e) => setFittingHeightM(e.target.value)}>
                        <option value="">Select Mounting</option>
                        {HEIGHT_CHOICES.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <label>Ceiling Height (m):</label>
                    <select value={ceilingHeightM} onChange={(e) => setCeilingHeightM(e.target.value)}>
                        <option value="">Select Mounting</option>
                        {HEIGHT_CHOICES.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Comments:</label>
                    <input
                        type="text"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />
                    {errors.comments && <span>{errors.comments}</span>}
                </div>
                <div>
                    <label>Connected Load (W):</label>
                    <input
                        type="text"
                        value={connectedLoadW}
                        onChange={(e) => setConnectedLoadW(e.target.value)}
                        readOnly
                    />
                    {errors.connectedLoadW && (
                        <span>{errors.connectedLoadW}</span>
                    )}
                </div>
                <div>
                    <label>Sensor Benefit:</label>
                    <input
                        type="text"
                        value={sensorBenefit}
                        onChange={(e) => setSensorBenefit(e.target.value)}
                    />
                    {errors.sensorBenefit && (
                        <span>{errors.sensorBenefit}</span>
                    )}
                </div>
                <div>
                    <label>Total Connected Load (kW):</label>
                    <input
                        type="text"
                        value={totalConnectedLoadKw}
                        onChange={(e) => setTotalConnectedLoadKw(e.target.value)}
                        readOnly
                    />
                    {errors.totalConnectedLoadKw && (
                        <span>{errors.totalConnectedLoadKw}</span>
                    )}
                </div>
                <div>
                    <label>Annual Usage (kWh):</label>
                    <input
                        type="text"
                        value={annualUsageKwh}
                        onChange={(e) => setAnnualUsageKwh(e.target.value)}
                        readOnly
                    />
                    {errors.annualUsageKwh && (
                        <span>{errors.annualUsageKwh}</span>
                    )}
                </div>
                <div>
                    <label>Proposed PLS Code:</label>
                    <select value={proposedPlsCode} onChange={(e) => handleProposedPlsCodeChange(e.target.value)}>
                        <option value="">Select PLS Code</option>
                        {proposedData.slice(1).map((row, index) => (
                            <option key={index} value={row[0]}>{row[0]}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Proposed Product:</label>
                    <input
                        type="text"
                        value={proposedProduct}
                        onChange={(e) => setProposedProduct(e.target.value)}
                        readOnly
                    />
                    {errors.proposedProduct && (
                        <span>{errors.proposedProduct}</span>
                    )}
                </div>
                <div>
                    <label>Proposed Quantity:</label>
                    <input
                        type="text"
                        value={proposedQuantity}
                        onChange={(e) => setProposedQuantity(e.target.value)}
                    />
                    {errors.proposedQuantity && (
                        <span>{errors.proposedQuantity}</span>
                    )}
                </div>
                <div>
                    <label>Proposed Connected Load (W):</label>
                    <input
                        type="text"
                        value={proposedConnectedLoadW}
                        onChange={(e) => setProposedConnectedLoadW(e.target.value)}
                        readOnly
                    />
                    {errors.proposedConnectedLoadW && (
                        <span>{errors.proposedConnectedLoadW}</span>
                    )}
                </div>
                <div>
                    <label>Proposed Sensor Benefit:</label>
                    <input
                        type="text"
                        value={proposedSensorBenefit}
                        onChange={(e) => setProposedSensorBenefit(e.target.value)}
                    />
                    {errors.proposedSensorBenefit && (
                        <span>{errors.proposedSensorBenefit}</span>
                    )}
                </div>
                <div>
                    <label>Total Max Connected Load (kW):</label>
                    <input
                        type="text"
                        value={totalMaxConnectedLoadKw}
                        onChange={(e) => setTotalMaxConnectedLoadKw(e.target.value)}
                        readOnly
                    />
                    {errors.totalMaxConnectedLoadKw && (
                        <span>{errors.totalMaxConnectedLoadKw}</span>
                    )}
                </div>
                <div>
                    <label>Proposed Annual Usage (kWh):</label>
                    <input
                        type="text"
                        value={proposedAnnualUsageKwh}
                        onChange={(e) => setProposedAnnualUsageKwh(e.target.value)}
                        readOnly
                    />
                    {errors.proposedAnnualUsageKwh && (
                        <span>{errors.proposedAnnualUsageKwh}</span>
                    )}
                </div>
                <div>
                    <label>Enlighted Sensor:</label>
                    <select value={enlightedSensor} onChange={(e) => setEnlightedSensor(e.target.value)}>
                        <option value="">Select Enlighted Sensor</option>  
                        {ENLIGHTED_SENSOR.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                        ))}
                    </select>
                    {errors.enlightedSensor && (
                        <span>{errors.enlightedSensor}</span>
                    )}
                </div>
                <div>
                    <label>Enlighted PSU:</label>
                    <input
                        type="text"
                        value={enlightedPsu}
                        onChange={(e) => setEnlightedPsu(e.target.value)}
                    />
                    {errors.enlightedPsu && (
                        <span>{errors.enlightedPsu}</span>
                    )}
                </div>
                <div>
                    <label>Enlighted Mounting:</label>
                    <input
                        type="text"
                        value={enlightedMounting}
                        onChange={(e) => setEnlightedMounting(e.target.value)}
                    />
                    {errors.enlightedMounting && (
                        <span>{errors.enlightedMounting}</span>
                    )}
                </div>
                <div>
                    <label>Enlighted Cable:</label>
                    <input
                        type="text"
                        value={enlightedCable}
                        onChange={(e) => setEnlightedCable(e.target.value)}
                    />
                    {errors.enlightedCable && (
                        <span>{errors.enlightedCable}</span>
                    )}
                </div>
                <div>
                    <label>Number of Enlighted Switches:</label>
                    <input
                        type="text"
                        value={numberOfEnlightedSwitches}
                        onChange={(e) => setNumberOfEnlightedSwitches(e.target.value)}
                    />
                    {errors.numberOfEnlightedSwitches && (
                        <span>{errors.numberOfEnlightedSwitches}</span>
                    )}
                </div>
                <div>
                    <label>Unit Cost:</label>
                    <input
                        type="text"
                        value={unitCost}
                        onChange={(e) => setUnitCost(e.target.value)}
                    />
                    {errors.unitCost && <span>{errors.unitCost}</span>}
                </div>
                <div>
                    <label>Unit + Margin:</label>
                    <input
                        type="text"
                        value={unitMargin}
                        onChange={(e) => setUnitMargin(e.target.value)}
                        readOnly
                    />
                    {errors.unitMargin && <span>{errors.unitMargin}</span>}
                </div>
                <div>
                    <label>Total Unit Incl. Margin:</label>
                    <input
                        type="text"
                        value={totalUnitInclMargin}
                        onChange={(e) => setTotalUnitInclMargin(e.target.value)}
                        readOnly
                    />
                    {errors.totalUnitInclMargin && (
                        <span>{errors.totalUnitInclMargin}</span>
                    )}
                </div>
                <div>
                    <label>Install Cost:</label>
                    <input
                        type="text"
                        value={installCost}
                        onChange={(e) => setInstallCost(e.target.value)}
                    />
                    {errors.installCost && <span>{errors.installCost}</span>}
                </div>
                <div>
                    <label>Install + Margin:</label>
                    <input
                        type="text"
                        value={installMargin}
                        onChange={(e) => setInstallMargin(e.target.value)}
                        readOnly
                    />
                    {errors.installMargin && (
                        <span>{errors.installMargin}</span>
                    )}
                </div>
                <div>
                    <label>Materials Cost:</label>
                    <input
                        type="text"
                        value={materialsCost}
                        onChange={(e) => setMaterialsCost(e.target.value)}
                    />
                    {errors.materialsCost && (
                        <span>{errors.materialsCost}</span>
                    )}
                </div>
                <div>
                    <label>Materials + Margin:</label>
                    <input
                        type="text"
                        value={materialsMargin}
                        onChange={(e) => setMaterialsMargin(e.target.value)}
                        readOnly
                    />
                    {errors.materialsMargin && (
                        <span>{errors.materialsMargin}</span>
                    )}
                </div>
                <div>
                    <label>Total Install Incl. Margin:</label>
                    <input
                        type="text"
                        value={totalInstallInclMargin}
                        onChange={(e) => setTotalInstallInclMargin(e.target.value)}
                        readOnly
                    />
                    {errors.totalInstallInclMargin && (
                        <span>{errors.totalInstallInclMargin}</span>
                    )}
                </div>
                <div>
                    <label>Total Cost:</label>
                    <input
                        type="text"
                        value={totalCost}
                        onChange={(e) => setTotalCost(e.target.value)}
                        readOnly
                    />
                    {errors.totalCost && <span>{errors.totalCost}</span>}
                </div>
                <div>
                    <label>Cost of Sale to PLS:</label>
                    <input
                        type="text"
                        value={costOfSaleToPls}
                        onChange={(e) => setCostOfSaleToPls(e.target.value)}
                        readOnly
                    />
                    {errors.costOfSaleToPls && (
                        <span>{errors.costOfSaleToPls}</span>
                    )}
                </div>
                <div>
                    <label>Savings:</label>
                    <input
                        type="text"
                        value={savings}
                        onChange={(e) => setSavings(e.target.value)}
                        readOnly
                    />
                    {errors.savings && <span>{errors.savings}</span>}
                </div>
                <div>
                    <label>Payback:</label>
                    <input
                        type="text"
                        value={payback}
                        onChange={(e) => setPayback(e.target.value)}
                        readOnly
                    />
                    {errors.payback && <span>{errors.payback}</span>}
                </div>
                <button type="submit">Submit</button>
            </form>
            {success && <p>Building info added successfully!</p>}
        </div>
    )
}

export default EditBuildingName