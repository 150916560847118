import React, { useState, useEffect } from 'react';
import '../../css/Margins.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Margins = () => {
    const [unitMargin, setUnitMargin] = useState(10);
    const [installMargin, setInstallMargin] = useState(5);
    const [materialsMargin, setMaterialsMargin] = useState(8);
    const [error, setError] = useState(null);
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [margin, setMargin] = useState([]);
    

    useEffect(() => {
        const fetchMargin = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/margin/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setMargin(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching margins:', error);
                setError('Error fetching margins');
            }
        };

        fetchMargin();
    }, [projectId]);
    console.log('Margin:', margin);

    useEffect(() => {
        const fetchProjectInfo = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/projects/${projectId}`);
                if (response.data) {
                    // Response contains a single project object
                    setProject(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching project info:', error);
                setError('Error fetching project info');
            }
        };

        fetchProjectInfo();

    }, [projectId]);

    // Save the project name in a constant if project exists and is not empty
    const projectNames = project && project.length > 0 ? project[0].name : null;

    console.log('Project Name:', projectNames);

    return (
        <div>
            <br></br>
            <h1 className="project-name">{projectNames}</h1>
            <p className="title">- MARGINS % -</p>
            <br></br>
            <div className="margin-container">
                {margin.map((margin, index) => (
                    <>
                        <div className="margin-item" key={index}>
                            <h3>Unit Margin📦</h3>
                            <span>{margin.unit_margin}%</span>
                        </div>
                        <div className="margin-item" key={index}>
                            <h3>Install Margin🔧</h3>
                            <span>{margin.install_margin}%</span>
                        </div>
                        <div className="margin-item" key={index}>
                            <h3>Materials Margin🧱</h3>
                            <span>{margin.materials_margin}%</span>
                        </div>
                    </>
                ))}
            </div>
    {margin.length === 0 ? (
        <Link to={`/add_margins/${projectId}`} className="add-button">Add Margins</Link>
    ) : (
        <Link to={`/edit_margins/${projectId}`} className="edit-button">Edit Margins</Link>
    )}
</div>
    );
};

export default Margins;
