import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import '../../css/Loading.css';

const Loading = () => {
  return (
    <div className="loading-container">
      <TailSpin color="#FF6347" height={40} width={40} />
      <div className="loading-content">
        <h2 style={{ color: '#FF6347' }}>Initializing...</h2>
        <p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
          Please wait while loading the application...
        </p>
      </div>
    </div>
  );
};

export default Loading;