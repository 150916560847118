import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../css/NavBar.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { AuthContext } from "./auth/AuthContext";

function NavBar() {
  const { isLoggedIn } = useContext(AuthContext);
  const logout = useContext(AuthContext).logout;

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="/logo.png" alt="Logo" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
          {isLoggedIn &&<li className="nav-item">
              <Link to="/project_list" className="abcd">Projects</Link>
            </li>}
            {isLoggedIn && <li className="nav-item" style={{ marginLeft: '20px' }}>
              <Link to="/add_project" className="abc">Add Project</Link>
            </li>}
          </ul>

          <ul className="navbar-nav ms-auto">
            
          {isLoggedIn && (
              <li className="nav-item">
                <a href="/logout" className="btn btn-nav rounded-pill me-2" onClick={handleLogout}>Logout</a>
              </li>
            )}
            {!isLoggedIn &&<li className="nav-item">
              <Link to="/register" className="btn btn-nav rounded-pill me-2">Register</Link>
            </li>}
            {!isLoggedIn && <li className="nav-item">
              <Link to="/login" className="btn btn-nav rounded-pill me-2">Log in</Link>
            </li>}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;