import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function EditMargins() {
    const [unitMargin, setUnitMargin] = useState('');
    const [installMargin, setInstallMargin] = useState('');
    const [materialsMargin, setMaterialsMargin] = useState('');
    const [error, setError] = useState(null);
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [margin, setMargin] = useState([]);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchMargin() {
            try {
                const response = await axios.get(`https://light.quant.ac/api/margin/${projectId}/`);
                console.log('Margin response:', response.data); // Log response data

                if (Array.isArray(response.data)) {
                    // If response data is an array of margin objects, set it to state
                    setMargin(response.data);
                } else {
                    throw new Error('Invalid margin data format');
                }
            } catch (error) {
                console.error('Error fetching margin:', error.message);
                setError('Error fetching margin');
            }
        }
        fetchMargin();
    }, [projectId]);

    useEffect(() => {
        if (Array.isArray(margin) && margin.length > 0) {
            setUnitMargin(margin[0].unit_margin);
            setInstallMargin(margin[0].install_margin);
            setMaterialsMargin(margin[0].materials_margin);
        }
    }, [margin]);



    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('Form data:', { projectId, unitMargin, installMargin, materialsMargin });

        try {
            const response = await axios.put(`https://light.quant.ac/api/margin/${projectId}/`, null, {
                params: {
                    id: projectId,
                    unit_margin: unitMargin,
                    install_margin: installMargin,
                    materials_margin: materialsMargin,
                }
            });
            if (response && response.data) {
                console.log(response.data); // Log the response data
                // Set success message or perform further actions
                setSuccess(true);
                navigate(`/project/${projectId}`);
            }
            else {
                console.error('Invalid response:', response);
                // Set appropriate error message
                setError('Invalid response from the server');
            }
        }
        catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            // Set error message based on the response or general error
            setError(error.response ? error.response.data.error : error.message);
        }
    }
    return (
        <div>
            <h1>Edit Margins</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Unit Margin:</label>
                    <input type="number" value={unitMargin} onChange={(e) => setUnitMargin(e.target.value)} />
                </div>
                <div>
                    <label>Install Margin:</label>
                    <input type="number" value={installMargin} onChange={(e) => setInstallMargin(e.target.value)} />
                </div>
                <div>
                    <label>Materials Margin:</label>
                    <input type="number" value={materialsMargin} onChange={(e) => setMaterialsMargin(e.target.value)} />
                </div>
                <button type="submit">Save</button>
            </form>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">Data saved successfully</p>}
        </div>
    );
}

export default EditMargins;

