import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';

function AddProjectForm() {
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Log the form data before sending the request
        console.log('Form data:', { name });
    
        try {
            const response = await axios.post(`https://light.quant.ac/api/projects/`, null, {params: { name }});
    
            if (response && response.data) {
                console.log(response.data); // Log the response data
                // Set success message or perform further actions
                setSuccess(true);
                navigate(`/project_list/`);
            } else {
                console.error('Invalid response:', response);
                // Set appropriate error message
                setErrors({ name: 'Invalid response from the server' });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            // Set error message based on the response or general error
            setErrors({ name: error.response ? error.response.data.error : error.message });
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="abcdef">Add Project</h1>
            <form onSubmit={handleSubmit} className="row g-3">
                {success && <div className="alert alert-success">Project added successfully!</div>}
                <div className="col-md-6">
                    <div className="form-floating">
                        <input 
                            type="text" 
                            className={`form-control ${errors.name && 'is-invalid'}`} 
                            id="name" 
                            name="name" 
                            value={name} 
                            placeholder='Name'
                            onChange={(e) => setName(e.target.value)} 
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <button type="submit" className="btn btn-primary">Add Project</button>
                </div>
            </form>
        </div>
    );
}

export default AddProjectForm;
