import React from 'react';
import '../css/First.css';

const First = () => {
  const today = new Date();
  const day = today.getDay();
  const date = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const greeting =
    day === 0
      ? 'Happy Sunday and welcome to our site!'
      : day === 1 
      ? 'Happy Monday and welcome to our site!'
      : day === 2
      ? 'Happy Tuesday and welcome to our site!'
      : day === 3
      ? 'Happy Wednesday and welcome to our site!'
      : day === 4
      ? 'Happy Thursday and welcome to our site!'
      : day === 5
      ? 'Happy Friday and welcome to our site!'
      : day === 6
      ? 'Happy Saturday and welcome to our site!'
      : 'Welcome to our site!';

  return (
    <div className="landing-page">
      <h1 className="greeting">{greeting}</h1>
      <p className="date">Today is: {month}/{date}/{year}</p>
      <h2 className="projects-heading">Check out our latest projects</h2>
      <ul className="project-list">
        <li className="project-item">
          <h3 className="project-title">Project 1</h3>
          <p className="project-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </li>
        <li className="project-item">
          <h3 className="project-title">Project 2</h3>
          <p className="project-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </li>
        <li className="project-item">
          <h3 className="project-title">Project 3</h3>
          <p className="project-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default First;