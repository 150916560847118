import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import '../css/CsvUpload.css';

function CSVUploader() {
  const [existingFile, setExistingFile] = useState();
  const [proposedFile, setProposedFile] = useState();
  const [existingData, setExistingData] = useState([]);
  const [proposedData, setProposedData] = useState([]);
  const navigate = useNavigate();

  // Load data from local storage on component mount
  useEffect(() => {
    const storedExistingData = localStorage.getItem("existingData");
    if (storedExistingData) {
      setExistingData(JSON.parse(storedExistingData));
    }
    console.log('Existing data:', existingData);
    console.log('Proposed data:', proposedData);

    const storedProposedData = localStorage.getItem("proposedData");
    if (storedProposedData) {
      setProposedData(JSON.parse(storedProposedData));
    }
  }, []);

  const handleExistingFileChange = (e) => {
    setExistingFile(e.target.files[0]);
  };

  const handleProposedFileChange = (e) => {
    setProposedFile(e.target.files[0]);
  };

  const handleOnSubmit = (e, fileType) => {
    e.preventDefault();

    let file;
    let setDataFunction;
    let localStorageKey;

    if (fileType === "existing") {
      file = existingFile;
      setDataFunction = setExistingData;
      localStorageKey = "existingData";
    } else if (fileType === "proposed") {
      file = proposedFile;
      setDataFunction = setProposedData;
      localStorageKey = "proposedData";
    }

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const binaryString = event.target.result;
        const workbook = XLSX.read(binaryString, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Remove empty rows
        const filteredData = data.filter((row) => row.length > 0);

        // Log the uploaded CSV data
        console.log(`Uploaded ${fileType} CSV data:`, filteredData);

        // Store the uploaded CSV data in component state
        setDataFunction(filteredData);

        // Save data to local storage
        localStorage.setItem(localStorageKey, JSON.stringify(filteredData));

        // Navigate to another route and pass the data as state
        navigate(`/csv/${fileType}`, { state: { data: filteredData } });
      };

      fileReader.readAsBinaryString(file);
    } else {
      alert("Please select a file.");
    }
  };

  return (
    <div className="csv-uploader">
      <div className="upload-section">
        <h2>Upload Existing Excel</h2>
        <form onSubmit={(e) => handleOnSubmit(e, "existing")}>
          <input type="file" accept=".xlsx" onChange={handleExistingFileChange} />
          <button type="submit">Upload Excel</button>
        </form>
      </div>

      <div className="upload-section">
        <h2>Upload Proposed Excel</h2>
        <form onSubmit={(e) => handleOnSubmit(e, "proposed")}>
          <input type="file" accept=".xlsx" onChange={handleProposedFileChange} />
          <button type="submit">Upload Excel</button>
        </form>
      </div>

      <div className="uploaded-data-section">
        <h2>Uploaded Data</h2>
        <div className="uploaded-data">
          <div className="table-responsive">
            <h3>Existing Products</h3>
            <table>
              <thead>
                <tr>
                  <th>PLS CODE</th>
                  <th>NAME</th>
                  <th>Connected Load W</th>
                </tr>
              </thead>
              <tbody>
                {existingData.map((row, index) => (
                  <tr key={index}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-responsive">
            <h3>Proposed Products</h3>
            <table>
              <thead>
                <tr>
                  <th>PLS CODE</th>
                  <th>NAME</th>
                  <th>Connected Load W</th>
                  <th>Unit Cost £ PROPOSED</th>
                </tr>
              </thead>
              <tbody>
                {proposedData.map((row, index) => (
                  <tr key={index}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CSVUploader;
