import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../../css/AddProject.css'

function AddMargins() {
    const [unitMargin, setUnitMargin] = useState(10);
    const [installMargin, setInstallMargin] = useState(5);
    const [materialsMargin, setMaterialsMargin] = useState(8);
    const [error, setError] = useState(null);
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [margin, setMargin] = useState([]);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('Form data:', { projectId, unitMargin, installMargin, materialsMargin });

        try {
            const response = await axios.post(`https://light.quant.ac/api/margin/${projectId}`, null, {
                params: {
                    project_id: projectId,
                    unit_margin: unitMargin,
                    install_margin: installMargin,
                    materials_margin: materialsMargin
                }
            });

            if (response && response.data) {
                console.log(response.data); // Log the response data
                // Set success message or perform further actions
                setSuccess(true);
                navigate(`/project/${projectId}`);
            } else {
                console.error('Invalid response:', response);
                // Set appropriate error message
                setError('Invalid response from the server');
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            // Set error message based on the response or general error
            setError(error.response ? error.response.data.error : error.message);
        }
    };

    return (
        <div>
            <h1>Edit Margins</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Unit Margin:</label>
                    <input type="number" value={unitMargin} onChange={(e) => setUnitMargin(e.target.value)} />
                </div>
                <div>
                    <label>Install Margin:</label>
                    <input type="number" value={installMargin} onChange={(e) => setInstallMargin(e.target.value)} />
                </div>
                <div>
                    <label>Materials Margin:</label>
                    <input type="number" value={materialsMargin} onChange={(e) => setMaterialsMargin(e.target.value)} />
                </div>
                <button type="submit">Save</button>
            </form>
            {success && <p>Margin saved successfully</p>}
            {error && <p>Error: {error}</p>}
        </div>
    );  
}

export default AddMargins;
