// Login.jsx
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import '../../css/Authentification.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://light.quant.ac/api/login/', null, {
        params: {
          username: username,
          password: password,
        },
      });

      if (response && response.data) {
        // Log the response data
        console.log(response.data);

        // Set isLoggedIn to true on successful login
        login();

        // Redirect to another page or perform further actions
        navigate(`/project_list/`);
      } else {
        console.error('Invalid response:', response);
        setError('Invalid response from the server');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.detail : error.message);
    }
  };

  return (
    <div className="container" id="container">
      <div className="form-container sign-up-container">
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          <h1 className="h1-class">Conecteaza-te la contul tau</h1>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="input-class"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="input-class"
          />
          <button type="submit" className="button-class">
            Log in
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;