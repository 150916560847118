import React from "react";
import axios from 'axios';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useLocation} from 'react-router-dom';
import '../../css/ProjectSummary.css'
import { useParams } from 'react-router-dom';

function ProjectSummary() {
    const [projectSummary, setProjectSummary] = useState([]);
    const [error, setError] = useState(null);
    const { projectId } = useParams();


    useEffect(() => {
        const fetchProjectSummary = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/project_summary/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setProjectSummary(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchProjectSummary();
    }, [projectId]);
    return (
        <div className="container mt-4">
            {error && <div>Error: {error}</div>}
            <div className="table-responsive">
            <table className="summary-table">
                <thead>
                    <tr>
                        <th>ANNUAL</th>
                        <th>EXISTING</th>
                        <th>PROPOSED</th>
                        <th>SAVINGS</th>
                    </tr>
                </thead>
                <tbody>
                        <React.Fragment key={projectSummary.pk}>
                            <tr>
                                <td>TOTAL CONNECTED LOAD (kW)</td>
                                <td>{projectSummary.total_connected_load_existing}</td>
                                <td>{projectSummary.total_connected_load_proposed}</td>
                                <td>{projectSummary.total_connected_load_existing - projectSummary.total_connected_load_proposed}</td>
                            </tr>
                            <tr>
                                <td>TOTAL ENERGY USAGE (kWh)</td>
                                <td>{projectSummary.total_energy_usage_existing}</td>
                                <td>{projectSummary.total_energy_usage_proposed}</td>
                                <td>{projectSummary.total_energy_usage_existing - projectSummary.total_energy_usage_proposed}</td>
                            </tr>
                            <tr>
                                <td>ENERGY COST</td>
                                <td>£{projectSummary.energy_cost_existing}</td>
                                <td>£{projectSummary.energy_cost_proposed}</td>
                                <td>{projectSummary.energy_cost_existing - projectSummary.energy_cost_proposed}</td>
                            </tr>
                            <tr>
                                <td>MAINTENANCE COST</td>
                                <td>£{projectSummary.maintenance_cost_existing}</td>
                                <td>£{projectSummary.maintenance_cost_proposed}</td>
                                <td>{projectSummary.maintenance_cost_existing - projectSummary.maintenance_cost_proposed}</td>
                            </tr>
                            <tr>
                                <td>TOTAL COST</td>
                                <td>£{projectSummary.total_cost_existing}</td>
                                <td>£{projectSummary.total_cost_proposed}</td>
                                <td>{projectSummary.total_cost_existing - projectSummary.total_cost_proposed}</td>
                            </tr>
                            <tr>
                                <td>TOTAL PROJECT COST</td>
                                <td colSpan="3">£{projectSummary.total_project_cost}</td>
                            </tr>
                            <tr>
                                <td>PAYBACK (YRS)</td>
                                <td colSpan="3">{projectSummary.payback_years}</td>
                            </tr>
                        </React.Fragment>

                </tbody>
            </table>
            </div>
        </div>
    );
    
}

export default ProjectSummary;