import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../css/RoomTypeList.css'

function RoomTypeList() {
    const [roomType, setRoomType] = useState([]);
    const [error, setError] = useState(null);
    const { projectId } = useParams();

    useEffect(() => {
        const fetchRoomType = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/room_types/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setRoomType(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchRoomType();
    }, [projectId]);

    
    return (
        <div className="room-type-list-container">
            <h1 className="room-type-list-header">Available 'Room Types' for this project:</h1>
            {error && <p className="room-type-list-error">Error: {error}</p>}
            <ul className="room-type-list">
                {roomType.map(type => (
                    <li key={type.pk}>
                          {type.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RoomTypeList