import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ProjectList from "../project/ProjectList";
import { useParams } from 'react-router-dom';
import CreateFacilityArea from "./CreateFacilityArea";

function FacilityArea() {
    const [facilityArea, setFacilityArea] = useState([]);
    const [error, setError] = useState(null);
    const { projectId } = useParams();

    useEffect(() => {
        const fetchFacilityArea = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/facility_areas/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setFacilityArea(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchFacilityArea();
    }, [projectId]);


    return (
        <div>
            <CreateFacilityArea />
            <h1>Available Facility Areas for this project:</h1>
            {error && <p>Error: {error}</p>}
            <ul>
                {facilityArea.map(area => (
                    <li key={area.pk}>
                        {area.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default FacilityArea