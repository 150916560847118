import React from 'react';
import '../../css/ProjectDetails.css';
import BuildingInfo from '../building/BuildingInfo';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';



function ProjectDetail() {
    const [buildingData, setBuildingData] = useState([]);
    const [buildingInfo, setBuildingInfo] = useState([]);
    const [error, setError] = useState(null);
    const { projectId } = useParams();
    const location = useLocation();
    const projectName = location.state ? location.state.projectName : null;
    console.log('buildingData:', buildingData);
    useEffect(() => {
        const fetchBuildingData = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/building_names/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setBuildingData(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchBuildingData();
    }, [projectId]);

     


console.log(buildingData)



    return (
        <div>
            <BuildingInfo />
            <br></br>
            <br></br>
            <div className="table-responsive">
            <table className="table table-bordered table-striped">
                <thead className='thead-dark'>
                    <tr>
                        <th>Edit✏️/ Delete❌</th>
                        <th>Facility Area</th>
                        <th>Room Number</th>
                        <th>Room Type</th>
                        <th>Hours</th>
                        <th>Days</th>
                        <th>Site Hours/Year</th>
                        <th>Sensor</th>
                        <th>Fraction of Operating Hours</th>
                        <th>Effective Rooms Running Hours</th>
                        <th>PLS Code</th>
                        <th>Existing Fitting</th>
                        <th>Qty</th>
                        <th>Sensor Type</th>
                        <th>Driver Type</th>
                        <th>Operating Switch Type</th>
                        <th>No. of Switches</th>
                        <th>Mounting</th>
                        <th>Fitting Height (m)</th>
                        <th>Ceiling Height (m)</th>
                        <th>Comments</th>
                        <th>Connected Load (W)</th>
                        <th>Sensor Benefit</th>
                        <th>Total Connected Load (kW)</th>
                        <th>Annual Usage (kWh)</th>
                        <th>PLS Code (Proposed Product)</th>
                        <th>Proposed Product</th>
                        <th>Qty (Proposed Product)</th>
                        <th>Connected Load (W) (Proposed Product)</th>
                        <th>Sensor Benefit (Proposed Product)</th>
                        <th>Total Max Connected Load (kW)</th>
                        <th>Annual Usage (kWh) (Proposed Product)</th>
                        <th>Enlighted Sensor</th>
                        <th>Enlighted PSU</th>
                        <th>Mounting (Enlighted)</th>
                        <th>Cable (Enlighted)</th>
                        <th>No. of Enlighted Switches</th>
                        <th>Unit Cost</th>
                        <th>Unit + Margin</th>
                        <th>Total Unit incl Margin</th>
                        <th>Install Cost</th>
                        <th>Install + Margin</th>
                        <th>Materials Cost</th>
                        <th>Materials + Margin</th>
                        <th>Total Install incl Margin</th>
                        <th>Total Cost</th>
                        <th>Cost of Sale to PLS</th>
                        <th>Savings</th>
                        <th>Payback</th>
                    </tr>
                </thead>
                <tbody>
                    {buildingData.map(building => (
                        <tr key={building.id}>
                            
                                                        <td>
                                                            <Link to={`/edit_building_name/${building.id}/${projectId}`} className="btn btn-secondary btn-sm">Edit</Link>
                                                            <Link to={`/delete_building_name/${building.id}/${projectId}`} className="btn btn-danger btn-sm">Delete</Link>
                                                        </td>
                                                        <td>{building.facility_area_id}</td>
                                                        <td>{building.room_number}</td>
                                                        <td>{building.room_type_id}</td>
                                                        <td>{building.hours_per_day}</td>
                                                        <td>{building.days_per_week}</td>
                                                        <td>
                                                        {building.hours_per_day * building.days_per_week * 50}
                                                        </td>
                                                        <td>{building.sensor ? 'Yes' : 'No'}</td>
                                                        <td>{building.fraction_of_operating_hours}%</td>
                                                        <td>{building.effective_rooms_running_hours}</td>
                                                        <td>{building.pls_code}</td>
                                                        <td>{building.existing_fitting}</td>
                                                        <td>{building.quantity}</td>
                                                        <td>{building.sensor_type}</td>
                                                        <td>{building.driver_type}</td>
                                                        <td>{building.operating_switch_type}</td>
                                                        <td>{building.number_of_switches}</td>
                                                        <td>{building.mounting}</td>
                                                        <td>{building.fitting_height_m}</td>
                                                        <td>{building.ceiling_height_m}</td>
                                                        <td>{building.comments}</td>
                                                        <td>{building.connected_load_w}</td>
                                                        <td>{building.sensor_benefit}</td>
                                                        <td>{building.total_connected_load_kw}</td>
                                                        <td>{building.annual_usage_kwh}</td>
                                                        <td>{building.proposed_pls_code}</td>
                                                        <td>{building.proposed_product}</td>
                                                        <td>{building.proposed_quantity}</td>
                                                        <td>{building.proposed_connected_load_w}</td>
                                                        <td>{building.proposed_sensor_benefit}</td>
                                                        <td>{building.total_max_connected_load_kw}</td>
                                                        <td>{building.proposed_annual_usage_kwh}</td>
                                                        <td>{building.enlighted_sensor}</td>
                                                        <td>{building.enlighted_psu}</td>
                                                        <td>{building.enlighted_mounting}</td>
                                                        <td>{building.enlighted_cable}</td>
                                                        <td>{building.number_of_enlighted_switches}</td>
                                                        <td>£{building.unit_cost}</td>
                                                        <td>£{building.unit_margin}</td>
                                                        <td>£{building.total_unit_incl_margin}</td>
                                                        <td>£{building.install_cost}</td>
                                                        <td>£{building.install_margin}</td>
                                                        <td>£{building.materials_cost}</td>
                                                        <td>£{building.materials_margin}</td>
                                                        <td>£{building.total_install_incl_margin}</td>
                                                        <td>£{building.total_cost}</td>
                                                        <td>£{building.cost_of_sale_to_pls}</td>
                                                        <td>£{building.savings}</td>
                                                        <td>{building.payback}</td>

                            {/* Render other fields similarly */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
            <Link to={`/create_building_name/${projectId}`} className="create-link">Create New Building Name</Link>

        </div>
    );
}

export default ProjectDetail;
