// LoginComponent.jsx

import React, { useState } from 'react';
import axios from 'axios';
import '../../css/Authentification.css'
import { useNavigate } from 'react-router-dom';

const RegisterAuth = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const navigate = useNavigate();
    

    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!strongPasswordRegex.test(password)) {
            setError('Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }

        try {
            const response = await axios.post('https://light.quant.ac/api/register/',null,{params: {
                username: username,
                email: email,
                password: password,
                verification_code: verificationCode
        }});
    
            if (response && response.data) {
                console.log(response.data); // Log the response data
                // Redirect to another page or perform further actions
                navigate(`/project_list/`);
            } else {
                console.error('Invalid response:', response);
                setError('Invalid response from the server');
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setError(error.response ? error.response.data.detail : error.message); // Display the error detail
        }
    };
    
    

    return (
        <div className="container" id="container">
            <div className="form-container sign-up-container">
                <form onSubmit={handleSubmit}>
                    {error && <p className="error-message">{error}</p>}
                    <h1 className="h1-class">Conecteaza-te la contul tau</h1>
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" className="input-class" />
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="input-class" />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="input-class" />
                    <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} placeholder="Verification Code" className="input-class" />
                    <button type="submit" className="button-class">Log in</button>
                </form>
            </div>
        </div>
    );
};

export default RegisterAuth;
