import React, { useState } from "react";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function AddBuildingInfo({ }) {
    const [name, setName] = useState('');
    const [photo, setPhoto] = useState(null);
    const [daysPerWeek, setDaysPerWeek] = useState('');
    const [hoursPerDay, setHoursPerDay] = useState('');
    const [weeksPerYear, setWeeksPerYear] = useState('');
    const [electricityPricePerKwh, setElectricityPricePerKwh] = useState('');
    const [co2ConversionFactor, setCo2ConversionFactor] = useState('');
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const { projectId } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('Form data:', { name, projectId, daysPerWeek });


        try {
            const response = await axios.post(`https://light.quant.ac/api/building_infos/${projectId}/`, null, {
                params: {
                    name: name,
                    projectId: projectId,
                    photo: photo,
                    days_per_week: daysPerWeek,
                    hours_per_day: hoursPerDay,
                    weeks_per_year: weeksPerYear,
                    electricity_price_per_kwh: electricityPricePerKwh,
                    co2_conversion_factor: co2ConversionFactor,


                }
            });

            if (response && response.data) {
                console.log(response.data); // Log the response data
                // Set success message or perform further actions
                setSuccess(true);
                navigate(`/project/${projectId}`);
            } else {
                console.error('Invalid response:', response);
                // Set appropriate error message
                setErrors({ name: 'Invalid response from the server' });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            // Set error message based on the response or general error
            setErrors({ name: error.response ? error.response.data.error : error.message });
        }
    };

    return (
        <div>
            <h1>Add Building Info</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    {errors.name && <span>{errors.name}</span>}
                </div>
                <div>
                    <label>Photo:</label>
                    <input type="file" onChange={(e) => setPhoto(e.target.files[0])} />
                    {errors.photo && <span>{errors.photo}</span>}
                </div>
                <div>
                    <label>Days Per Week:</label>
                    <input type="number" value={daysPerWeek} onChange={(e) => setDaysPerWeek(e.target.value)} />
                </div>
                <div>
                    <label>Hours Per Day:</label>
                    <input type="number" value={hoursPerDay} onChange={(e) => setHoursPerDay(e.target.value)} />
                </div>
                <div>
                    <label>Weeks Per Year:</label>
                    <input type="number" value={weeksPerYear} onChange={(e) => setWeeksPerYear(e.target.value)} />
                </div>
                <div>
                    <label>Electricity Price Per kWh:</label>
                    <input type="number" value={electricityPricePerKwh} onChange={(e) => setElectricityPricePerKwh(e.target.value)} />
                </div>
                <div>
                    <label>CO2 Conversion Factor:</label>
                    <input type="number" value={co2ConversionFactor} onChange={(e) => setCo2ConversionFactor(e.target.value)} />
                </div>
                <button type="submit">Submit</button>
            </form>
            {success && <p>Building info added successfully!</p>}
        </div>
    );
}

export default AddBuildingInfo;
