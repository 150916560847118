import React from "react";
import RoomTypeList from "./RoomTypeList";
import '../../css/CreateRoomType.css'; 
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';

function CreateRoomType({}) {

    const [name, setName] = useState('');
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const { projectId } = useParams();
    const navigate = useNavigate();
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Log the form data before sending the request
        console.log('Form data:', { name, projectId });

        try {
            const response = await axios.post(`https://light.quant.ac/api/room_types/${projectId}/`,null,{params: {
                name: name,
                projectId: projectId,
        }});

            if (response && response.data) {
                console.log(response.data);
                setSuccess(true);
                navigate(`/project/${projectId}`);
            } else {
                console.error('Invalid response:', response);
                setErrors({ name: 'Invalid response from the server' });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setErrors({ name: error.response ? error.response.data.error : error.message });
        }
    };
    return (
        <div className="create-room-type-container">
            
            <form onSubmit={handleSubmit}>
                <div>
                    <label className="name">Name:</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <button type="submit">Submit</button>
            </form>
            {success && <p>Room type created successfully!</p>}
            {errors && (
                <div>
                    {Object.values(errors).map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}
            <RoomTypeList/>
        </div>
    );
}

export default CreateRoomType;
