import React, { useState } from "react";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../../css/DeleteBuildingName.css'

function DeleteBuildingName() {
    const { projectId , pk} = useParams();
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);


    const handleDelete = async () => {
        try {
            console.log('Deleting building name for project:', projectId);
            const response = await axios.delete(`https://light.quant.ac/api/building_names/${pk}/`);
            console.log('Delete response:', response);
            if (response.status === 200) {
                console.log('Building name deleted successfully');
                setSuccess(true);
                navigate(`/project/${projectId}`);
            } else {
                console.log('Failed to delete building name:', response.data);
                setError('Failed to delete building name');
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setError('Failed to delete building name');
        }
    };
    
    

    return (
        <div className="delete-building-container">
            <h1 className="delete-title">Are you sure you want to delete the record from the table?</h1>
            {error && <p className="delete-error-message">{error}</p>}
            <div className="button-container">
    <button onClick={handleDelete}>Delete Building</button>
    <button className="cancel" onClick={() => navigate(`/project/${projectId}`)}>Cancel</button>
</div>
            {success && <p className="delete-success-message">Building name deleted successfully!</p>}
        </div>
    );
    }

export default DeleteBuildingName;
