import React from "react";
import axios from 'axios';
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import '../../css/ProjectTotals.css'
import { useParams } from 'react-router-dom';

function ProjectTotals() {
    const [buildingTotals, setBuildingTotals] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const { projectId } = useParams();
    const projectPk = location.state ? location.state.projectPk : null;
    console.log(typeof buildingTotals);
    useEffect(() => {
        const fetchBuildingTotals = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/building_names_totals/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setBuildingTotals(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchBuildingTotals();
    }, [projectId]);
    console.log(buildingTotals);
    return (
        <div className="container mt-4">
            <br></br>
            <div>
                <div className="existing-values">
                    <h2>Existing Energy Usage⚡️</h2>
                    <table>
                        <tbody>
                            <tr>
                                <th>Total Connected Load (Existing)</th>
                                <th>Total Annual Usage (Existing)</th>
                            </tr>
                            <tr>
                                <td>{buildingTotals.total_connected_load} kW</td>
                                <td>{buildingTotals.total_annual_usage} kWh</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div className="proposed-values">
                    <h2>Proposed Energy Usage⚡️</h2>
                    <table>
                        <tbody>
                            <tr>
                                <th>Total Max Connected Load (Proposed)</th>
                                <th>Proposed Annual Usage</th>
                            </tr>
                            <tr>
                                <td>{buildingTotals.total_max_connected_load} kW</td>
                                <td>{buildingTotals.proposed_annual_usage} kWh</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div className="total-costs">
                    <table>
                        <tbody>
                            <tr>
                                <th>Category</th>
                                <th>Total Cost</th>
                                <th>Cost of Sale to Pls</th>
                                <th>Savings</th>
                                <th>Payback</th>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>£{buildingTotals.total_cost}</td>
                                <td>£{buildingTotals.cost_of_sale_to_pls}</td>
                                <td>£{buildingTotals.savings}</td>
                                <td>{buildingTotals.payback}</td>
                            </tr>
                            <tr>
                                <td>Margin</td>
                                <td>{buildingTotals.total_cost - buildingTotals.cost_of_sale_to_pls}</td>
                                <td>{buildingTotals.margin}-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>Margin %</td>
                                <td>{(buildingTotals.total_cost - buildingTotals.cost_of_sale_to_pls)-buildingTotals.total_cost}%</td>
                                <td>{buildingTotals.margin_percentage}-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                    <br></br>
                    <h2>Total Savings Details💲</h2>
                    <table>
                        <tbody>
                            <tr>
                                <th>Total Saving (kWh)</th>
                                <th>Total Saving Percentage</th>
                                <th>Total Saving in Currency</th>
                                <th>Total Saving in CO2e</th>
                            </tr>
                            <tr>
                                <td>{buildingTotals.total_saving_kwh}</td>
                                <td>{buildingTotals.total_saving_percentage}%</td>
                                <td>£{buildingTotals.total_saving_in_currency}</td>
                                <td>{buildingTotals.total_saving_in_co2e}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br></br>
        </div>
    );
}

export default ProjectTotals;