import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import React, { useContext } from 'react';
import Loading from './Loading';

const PrivateRoute = ({ Component }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return isLoading ? <Loading /> : isLoggedIn ? <Component /> : <Navigate to={"/login"} replace />;
};

export default PrivateRoute;