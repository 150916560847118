import React from "react";
import '../../css/MaintenanceCostSum.css'
import EditMaintenanceCost from "./EditMaintenanceCost";
import axios from 'axios';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

function MaintenanceCostSum() {
    const [buildingMaintenance, setBuildingMaintenance] = useState([]);
    const [error, setError] = useState(null);
    const { projectId, pk } = useParams();
    const [addButtonClicked, setAddButtonClicked] = useState(false);

    useEffect(() => {
        const fetchBuildingMaintenance = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/maintenance_cost/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setBuildingMaintenance(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchBuildingMaintenance();
    }, [projectId]);

    const handleAddButtonClick = () => {
        setAddButtonClicked(true);
    };

    return (
        <div className="container mt-4">
            <h2 className="building-name">Maintenance Cost Summary</h2>
            {!addButtonClicked && buildingMaintenance.length === 0 && (
                <React.Fragment>
                    <Link to={`/add_maintenance_cost/${projectId}`} className="edit-button" onClick={handleAddButtonClick}>
                        Add Maintenance Cost
                    </Link>
                </React.Fragment>
                )}
            <div className="table-responsive">
            <table className="maintenance-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>EXISTING</th>
                        <th>PROPOSED</th>
                    </tr>
                </thead>
                <tbody>
                    {buildingMaintenance.map(cost => (
                        <React.Fragment key={cost.pk}>
                            <tr>
                                <td>Total no. light fittings</td>
                                <td>{cost.total_no_light_fittings_existing}</td>
                                <td>{cost.total_no_light_fittings_proposed}</td>
                            </tr>
                            <tr>
                                <td>Failure rate - lights (%)</td>
                                <td>{cost.failure_rate_lights_existing}%</td>
                                <td>{cost.failure_rate_lights_proposed}%</td>
                            </tr>
                            <tr>
                                <td>Cost per light (£)</td>
                                <td>£{cost.cost_per_light_existing}</td>
                                <td>£{cost.cost_per_light_proposed}</td>
                            </tr>
                            <tr>
                                <td>Failure rate - ballast (%)</td>
                                <td>{cost.failure_rate_ballast_existing}%</td>
                                <td>{cost.failure_rate_ballast_proposed}%</td>
                            </tr>
                            <tr>
                                <td>Cost per ballast (£)</td>
                                <td>£{cost.cost_per_ballast_existing}</td>
                                <td>£{cost.cost_per_ballast_proposed}</td>
                            </tr>
                            <tr>
                                <td>Electrician Hours</td>
                                <td>{cost.electrician_hours_existing}</td>
                                <td>{cost.electrician_hours_proposed}</td>
                            </tr>
                            <tr>
                                <td>Cost per Electrician Hour (£)</td>
                                <td>£{cost.cost_per_electrician_hour_existing}</td>
                                <td>£{cost.cost_per_electrician_hour_proposed}</td>
                            </tr>
                            <tr>
                                <td>Total Cost Lights (£)</td>
                                <td>£{cost.total_cost_lights_existing}</td>
                                <td>£{cost.total_cost_lights_proposed}</td>
                            </tr>
                            <tr>
                                <td>Total Cost Ballast (£)</td>
                                <td>£{cost.total_cost_ballast_existing}</td>
                                <td>£{cost.total_cost_ballast_proposed}</td>
                            </tr>
                            <tr>
                                <td>Total Cost Electrician (£)</td>
                                <td>£{cost.total_cost_electrician_existing}</td>
                                <td>£{cost.total_cost_electrician_proposed}</td>
                            </tr>
                            <tr>
                                <td>TOTAL MAINTENANCE COST (£)</td>
                                <td>£{cost.total_maintenance_cost_existing}</td>
                                <td>£{cost.total_maintenance_cost_proposed}</td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            </div>

            {buildingMaintenance.map(cost => (
    <React.Fragment key={cost.id}>
        {/* Table rows here */}
        <Link to={`/edit_maintenance_cost/${cost.id}/${projectId}`} className="edit-button">Edit Maintenance Cost</Link>
    </React.Fragment>
))}

        </div>
    );
}

export default MaintenanceCostSum;