import React from "react";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate  } from 'react-router-dom';

function EditMaintenanceCost() {
    const { projectId, pk} = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [totalNoLightFittingsExisting, setTotalNoLightFittingsExisting] = useState(0);
    const [totalNoLightFittingsProposed, setTotalNoLightFittingsProposed] = useState(0);
    const [failureRateLightsExisting, setFailureRateLightsExisting] = useState(10.0);
    const [failureRateLightsProposed, setFailureRateLightsProposed] = useState(2.5);
    const [costPerLightExisting, setCostPerLightExisting] = useState(15.00);
    const [costPerLightProposed, setCostPerLightProposed] = useState(15.00);
    const [failureRateBallastExisting, setFailureRateBallastExisting] = useState(10.0);
    const [failureRateBallastProposed, setFailureRateBallastProposed] = useState(1.0);
    const [costPerBallastExisting, setCostPerBallastExisting] = useState(35.00);
    const [costPerBallastProposed, setCostPerBallastProposed] = useState(30.00);
    const [electricianHoursExisting, setElectricianHoursExisting] = useState(40);
    const [electricianHoursProposed, setElectricianHoursProposed] = useState(10);
    const [costPerElectricianHourExisting, setCostPerElectricianHourExisting] = useState(35.00);
    const [costPerElectricianHourProposed, setCostPerElectricianHourProposed] = useState(35.00);
    const [totalCostLightsExisting, setTotalCostLightsExisting] = useState(0);
    const [totalCostLightsProposed, setTotalCostLightsProposed] = useState(0);
    const [totalCostBallastExisting, setTotalCostBallastExisting] = useState(0);
    const [totalCostBallastProposed, setTotalCostBallastProposed] = useState(0);
    const [totalCostElectricianExisting, setTotalCostElectricianExisting] = useState(0);
    const [totalCostElectricianProposed, setTotalCostElectricianProposed] = useState(0);
    const [totalMaintenanceCostExisting, setTotalMaintenanceCostExisting] = useState(0);
    const [totalMaintenanceCostProposed, setTotalMaintenanceCostProposed] = useState(0);

    useEffect(() => {
        const fetchBuildingMaintenance = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/maintenance_cost/${projectId}`);
                console.log('Maintenance response:', response.data); // Log response data
    
                const maintenanceCost = response.data[0]; // Access the first item in the array
                console.log(maintenanceCost); // Log maintenance cost data
    
                if (maintenanceCost) {
                    setTotalNoLightFittingsExisting(maintenanceCost.total_no_light_fittings_existing);
                    setTotalNoLightFittingsProposed(maintenanceCost.total_no_light_fittings_proposed);
                    setFailureRateLightsExisting(maintenanceCost.failure_rate_lights_existing);
                    setFailureRateLightsProposed(maintenanceCost.failure_rate_lights_proposed);
                    setCostPerLightExisting(maintenanceCost.cost_per_light_existing);
                    setCostPerLightProposed(maintenanceCost.cost_per_light_proposed);
                    setFailureRateBallastExisting(maintenanceCost.failure_rate_ballast_existing);
                    setFailureRateBallastProposed(maintenanceCost.failure_rate_ballast_proposed);
                    setCostPerBallastExisting(maintenanceCost.cost_per_ballast_existing);
                    setCostPerBallastProposed(maintenanceCost.cost_per_ballast_proposed);
                    setElectricianHoursExisting(maintenanceCost.electrician_hours_existing);
                    setElectricianHoursProposed(maintenanceCost.electrician_hours_proposed);
                    setCostPerElectricianHourExisting(maintenanceCost.cost_per_electrician_hour_existing);
                    setCostPerElectricianHourProposed(maintenanceCost.cost_per_electrician_hour_proposed);
                    setTotalCostLightsExisting(maintenanceCost.total_cost_lights_existing);
                    setTotalCostLightsProposed(maintenanceCost.total_cost_lights_proposed);
                    setTotalCostBallastExisting(maintenanceCost.total_cost_ballast_existing);
                    setTotalCostBallastProposed(maintenanceCost.total_cost_ballast_proposed);
                    setTotalCostElectricianExisting(maintenanceCost.total_cost_electrician_existing);
                    setTotalCostElectricianProposed(maintenanceCost.total_cost_electrician_proposed);
                    setTotalMaintenanceCostExisting(maintenanceCost.total_maintenance_cost_existing);
                    setTotalMaintenanceCostProposed(maintenanceCost.total_maintenance_cost_proposed);
                    
                } else {
                    throw new Error('Invalid building info data format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error.message);
                setErrors({ name: 'Error fetching building info' });
            }
        }

        fetchBuildingMaintenance();
    }, [projectId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log('Form data:', { pk, totalNoLightFittingsExisting, projectId, totalNoLightFittingsProposed });

    
        try {
            const response = await axios.put(`https://light.quant.ac/api/maintenance_cost/${parseInt(pk)}/`,null,{params: {
                    maintenance_cost_id: pk,
                    total_no_light_fittings_existing: parseInt(totalNoLightFittingsExisting),
                    total_no_light_fittings_proposed: parseInt(totalNoLightFittingsProposed),
                    failure_rate_lights_existing: parseFloat(failureRateLightsExisting),
                    failure_rate_lights_proposed: parseFloat(failureRateLightsProposed),
                    cost_per_light_existing: parseFloat(costPerLightExisting),
                    cost_per_light_proposed: parseFloat(costPerLightProposed),
                    failure_rate_ballast_existing: parseFloat(failureRateBallastExisting),
                    failure_rate_ballast_proposed: parseFloat(failureRateBallastProposed),
                    cost_per_ballast_existing: parseFloat(costPerBallastExisting),
                    cost_per_ballast_proposed: parseFloat(costPerBallastProposed),
                    electrician_hours_existing: parseInt(electricianHoursExisting),
                    electrician_hours_proposed: parseInt(electricianHoursProposed),
                    cost_per_electrician_hour_existing: parseFloat(costPerElectricianHourExisting),
                    cost_per_electrician_hour_proposed: parseFloat(costPerElectricianHourProposed),
                    total_cost_lights_existing: parseFloat(totalCostLightsExisting),
                    total_cost_lights_proposed: parseFloat(totalCostLightsProposed),
                    total_cost_ballast_existing: parseFloat(totalCostBallastExisting),
                    total_cost_ballast_proposed: parseFloat(totalCostBallastProposed),
                    total_cost_electrician_existing: parseFloat(totalCostElectricianExisting),
                    total_cost_electrician_proposed: parseFloat(totalCostElectricianProposed),
                    total_maintenance_cost_existing: parseFloat(totalMaintenanceCostExisting),
                    total_maintenance_cost_proposed: parseFloat(totalMaintenanceCostProposed),
                }
        });
    
            if (response && response.data) {
                console.log(response.data); // Log the response data
                // Set success message or perform further actions
                setSuccess(true);
                navigate(`/project/${projectId}`);
            } else {
                console.error('Invalid response:', response);
                // Set appropriate error message
                setErrors({ name: 'Invalid response from the server' });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            // Set error message based on the response or general error
            setErrors({ name: error.response ? error.response.data.error : error.message });
        }
    };

    useEffect(() => {
        setTotalCostLightsExisting((totalNoLightFittingsExisting * (failureRateLightsExisting/100) *costPerLightExisting) );
    }, [totalNoLightFittingsExisting,failureRateLightsExisting, costPerLightExisting]);

    useEffect(() => {
        setTotalCostLightsProposed((totalNoLightFittingsProposed * (failureRateLightsProposed/100) *costPerLightProposed) );
    }, [totalNoLightFittingsProposed,failureRateLightsProposed, costPerLightProposed]);

    useEffect(() => {
        setTotalCostBallastExisting((totalNoLightFittingsExisting * (failureRateBallastExisting/100) *costPerBallastExisting) );
    }, [totalNoLightFittingsExisting,failureRateBallastExisting, costPerBallastExisting]);

    useEffect(() => {
        const totalCostBallastProposeda =( (totalNoLightFittingsProposed * (failureRateBallastProposed/100) *costPerBallastProposed)).toFixed(2);
        setTotalCostBallastProposed(totalCostBallastProposeda);

    }, [totalNoLightFittingsProposed,failureRateBallastProposed, costPerBallastProposed]);

    useEffect(() => {
        setTotalCostElectricianExisting((electricianHoursExisting * costPerElectricianHourExisting) );
    }, [electricianHoursExisting,costPerElectricianHourExisting]);
    

    useEffect(() => {
        setTotalCostElectricianProposed((electricianHoursProposed * costPerElectricianHourProposed ) );
    }, [electricianHoursProposed,costPerElectricianHourProposed]);

    useEffect(() => {
        setTotalMaintenanceCostExisting((totalCostLightsExisting + totalCostBallastExisting +totalCostElectricianExisting ) );
    }, [totalCostLightsExisting,totalCostBallastExisting , totalCostElectricianExisting]);

    useEffect(() => {
        setTotalMaintenanceCostProposed((totalCostLightsProposed + totalCostBallastProposed+ totalCostElectricianProposed ) );
    }, [totalCostLightsProposed,totalCostBallastProposed,totalCostElectricianProposed]);

    
    

    return (
        <div>
            <h1>Edit Maintenance Cost - "nume proiect"</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="totalNoLightFittingsExisting">Total No. of Light Fittings (Existing):</label>
                <input type="number" id="totalNoLightFittingsExisting" value={totalNoLightFittingsExisting} onChange={(e) => setTotalNoLightFittingsExisting(e.target.value)} />

                <label htmlFor="totalNoLightFittingsProposed">Total No. of Light Fittings (Proposed):</label>
                <input type="number" id="totalNoLightFittingsProposed" value={totalNoLightFittingsProposed} onChange={(e) => setTotalNoLightFittingsProposed(e.target.value)} />

                <label htmlFor="failureRateLightsExisting">Failure Rate of Lights (Existing):</label>
                <input type="number" id="failureRateLightsExisting" value={failureRateLightsExisting} onChange={(e) => setFailureRateLightsExisting(e.target.value)} />

                <label htmlFor="failureRateLightsProposed">Failure Rate of Lights (Proposed):</label>
                <input type="number" id="failureRateLightsProposed" value={failureRateLightsProposed} onChange={(e) => setFailureRateLightsProposed(e.target.value)} />

                <label htmlFor="costPerLightExisting">Cost per Light (Existing):</label>
                <input type="number" id="costPerLightExisting" value={costPerLightExisting} onChange={(e) => setCostPerLightExisting(e.target.value)} />

                <label htmlFor="costPerLightProposed">Cost per Light (Proposed):</label>
                <input type="number" id="costPerLightProposed" value={costPerLightProposed} onChange={(e) => setCostPerLightProposed(e.target.value)} />

                <label htmlFor="failureRateBallastExisting">Failure Rate of Ballast (Existing):</label>
                <input type="number" id="failureRateBallastExisting" value={failureRateBallastExisting} onChange={(e) => setFailureRateBallastExisting(e.target.value)} />

                <label htmlFor="failureRateBallastProposed">Failure Rate of Ballast (Proposed):</label>
                <input type="number" id="failureRateBallastProposed" value={failureRateBallastProposed} onChange={(e) => setFailureRateBallastProposed(e.target.value)} />

                <label htmlFor="costPerBallastExisting">Cost per Ballast (Existing):</label>
                <input type="number" id="costPerBallastExisting" value={costPerBallastExisting} onChange={(e) => setCostPerBallastExisting(e.target.value)} />

                <label htmlFor="costPerBallastProposed">Cost per Ballast (Proposed):</label>
                <input type="number" id="costPerBallastProposed" value={costPerBallastProposed} onChange={(e) => setCostPerBallastProposed(e.target.value)} />

                <label htmlFor="electricianHoursExisting">Electrician Hours (Existing):</label>
                <input type="number" id="electricianHoursExisting" value={electricianHoursExisting} onChange={(e) => setElectricianHoursExisting(e.target.value)} />

                <label htmlFor="electricianHoursProposed">Electrician Hours (Proposed):</label>
                <input type="number" id="electricianHoursProposed" value={electricianHoursProposed} onChange={(e) => setElectricianHoursProposed(e.target.value)} />

                <label htmlFor="costPerElectricianHourExisting">Cost per Electrician Hour (Existing):</label>
                <input type="number" id="costPerElectricianHourExisting" value={costPerElectricianHourExisting} onChange={(e) => setCostPerElectricianHourExisting(e.target.value)} />

                <label htmlFor="costPerElectricianHourProposed">Cost per Electrician Hour (Proposed):</label>
                <input type="number" id="costPerElectricianHourProposed" value={costPerElectricianHourProposed} onChange={(e) => setCostPerElectricianHourProposed(e.target.value)} />

                <label htmlFor="totalCostLightsExisting">Total Cost of Lights (Existing):</label>
                <input type="number" id="totalCostLightsExisting" value={totalCostLightsExisting} onChange={(e) => setTotalCostLightsExisting(e.target.value)}readOnly />

                <label htmlFor="totalCostLightsProposed">Total Cost of Lights (Proposed):</label>
                <input type="number" id="totalCostLightsProposed" value={totalCostLightsProposed} onChange={(e) => setTotalCostLightsProposed(e.target.value)}readOnly />

                <label htmlFor="totalCostBallastExisting">Total Cost of Ballast (Existing):</label>
                <input type="number" id="totalCostBallastExisting" value={totalCostBallastExisting} onChange={(e) => setTotalCostBallastExisting(e.target.value)}readOnly />

                <label htmlFor="totalCostBallastProposed">Total Cost of Ballast (Proposed):</label>
                <input type="number" id="totalCostBallastProposed" value={totalCostBallastProposed} onChange={(e) => setTotalCostBallastProposed(e.target.value)} readOnly/>

                <label htmlFor="totalCostElectricianExisting">Total Cost of Electrician (Existing):</label>
                <input type="number" id="totalCostElectricianExisting" value={totalCostElectricianExisting} onChange={(e) => setTotalCostElectricianExisting(e.target.value)} readOnly/>

                <label htmlFor="totalCostElectricianProposed">Total Cost of Electrician (Proposed):</label>
                <input type="number" id="totalCostElectricianProposed" value={totalCostElectricianProposed} onChange={(e) => setTotalCostElectricianProposed(e.target.value)} readOnly/>

                <label htmlFor="totalMaintenanceCostExisting">Total Maintenance Cost (Existing):</label>
                <input type="number" id="totalMaintenanceCostExisting" value={totalMaintenanceCostExisting} onChange={(e) => setTotalMaintenanceCostExisting(e.target.value)} readOnly/>

                <label htmlFor="totalMaintenanceCostProposed">Total Maintenance Cost (Proposed):</label>
                <input type="number" id="totalMaintenanceCostProposed" value={totalMaintenanceCostProposed} onChange={(e) => setTotalMaintenanceCostProposed(e.target.value)} readOnly />

                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default EditMaintenanceCost