import React from "react";
import '../../css/ProjectDetails.css'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import '../../css/BuildingInfo.css'

function BuildingInfo() {
    const [buildingInfo, setBuildingInfo] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const projectName = location.state ? location.state.projectName : null;
    const { projectId } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const fetchBuildingInfo = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/building_infos/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setBuildingInfo(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchBuildingInfo();
    }, [projectId]);

    useEffect(() => {
        const fetchProjectInfo = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/projects/${projectId}`);
                if (response.data) {
                    // Response contains a single project object
                    setProject(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching project info:', error);
                setError('Error fetching project info');
            }
        };

        fetchProjectInfo();

        return () => {
            // Cleanup function if needed
        };
    }, [projectId]);

    // Save the project name in a constant if project exists and is not empty
    const projectNames = project && project.length > 0 ? project[0].name : null;

    console.log('Project Name:', projectNames);

    return (
        <div>
            <h1 className="project-name">{projectNames}</h1>
            <br></br>
            <div className="link-container">
                <Link to={`/project_summary/${projectId}`} className="summary-link">Project Summary📊</Link>
                <Link to={`/maintenance_cost_summary/${projectId}`} className="summary-link">View Maintenance Cost⚙️</Link>
                <Link to={`/project_totals/${projectId}`} className="summary-link">Project Totals Usage💡</Link>
                <Link to={`/create_room_type/${projectId}`} className="summary-link">Create Room Type🏢</Link>
                <Link to={`/facility_area/${projectId}`} className="summary-link">Create Facility Area🏭</Link>
                <Link to={`/pls_products/`} className="summary-link" style={{ marginTop: '10px' }}>PLS Products🛠️</Link>
                <Link to={`/margins/${projectId}`} className="summary-link" style={{ marginTop: '10px' }}>Margins📈</Link>


            </div>
            <br></br>
            <br></br>
            <div className="container mt-4">
                {error ? (
                    <div>
                        <p>No building information available for this project.</p>
                        <Link to={`/add_building_info/${projectId}`} className="add-button">Add Building Info</Link>
                    </div>
                ) : (
                    buildingInfo && (
                        <div className="table-responsive mt-3">
                            <h1 className="building-name">Calculations Based on:</h1>
                            <table className="table table-bordered table-striped">
                                <tbody className="tbody-dark">
                                    <tr>
                                        <td>Days per week:</td>
                                        <td>{buildingInfo.days_per_week}</td>
                                    </tr>
                                    <tr>
                                        <td>Hours Per Day:</td>
                                        <td>{buildingInfo.hours_per_day}</td>
                                    </tr>
                                    <tr>
                                        <td>Weeks per year:</td>
                                        <td>{buildingInfo.weeks_per_year}</td>
                                    </tr>
                                    <tr>
                                        <td>Electricity price £/kWh:</td>
                                        <td>£{buildingInfo.electricity_price_per_kwh}</td>
                                    </tr>
                                    <tr>
                                        <td>kg CO2e conversion factor:</td>
                                        <td>{buildingInfo.co2_conversion_factor}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Link to={`/edit_building_info/${buildingInfo.id}/${projectId}`} className="btn btn-secondary btn-sm">Edit Building Info</Link>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default BuildingInfo;