import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Link
import ProjectList from './components/project/ProjectList';
import RegisterAuth from './components/auth/RegisterAuth';
import ProjectDetail from './components/project/ProjectDetails';
import AddProjectForm from './components/project/AddProjectForm'; // Import AddProjectForm component
import CreateRoomType from './components/selector/CreateRoomType';
import CreateFacilityArea from './components/selector/CreateFacilityArea';
import MaintenanceCostSum from './components/maintenance/MaintenanceCostSum';
import NavBar from './components/NavBar';
import EditBuildingInfo from './components/building/EditBuildingInfo';
import AddBuildingInfo from './components/building/AddBuildingInfo'
import CreateBuildingName from './components/building/CreateBuildingName'
import EditBuildingName from './components/building/EditBuildingName';
import EditMaintenanceCost from './components/maintenance/EditMaintenanceCost';
import FacilityArea from './components/selector/FacilityArea';
import ProjectSummary from './components/project/ProjectSummary'
import RoomTypeList from './components/selector/RoomTypeList';
import ProjectTotals from './components/project/ProjectTotals';
import DeleteBuildingName from './components/building/DeleteBuildingName';
import AddMaintenanceCost from './components/maintenance/AddMaintenanceCost';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import { AuthContext, AuthProvider } from './components/auth/AuthContext';
import First from './components/First';
import PrivateRoute from './components/auth/PrivateRoute';
import CSVUploader from './components/CsvUpload';
import Margins from './components/margin/Margins';
import AddMargins from './components/margin/AddMargins';
import EditMargins from './components/margin/EditMargins';

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    return (
        <><AuthProvider>
            <Router>
                <div>
                    <NavBar />
                    <Routes>
                        <Route path="/" element={<First />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<RegisterAuth />} />
                        <Route path="/add_building_info/:projectId" element={<PrivateRoute Component={AddBuildingInfo} />} />
                        <Route path="/add_project" element={<PrivateRoute Component={AddProjectForm} />} />
                        <Route path="/create_building_name/:projectId" element={<PrivateRoute Component={CreateBuildingName} />} />
                        <Route path="/create_facility_area/:projectId" element={<PrivateRoute Component={CreateFacilityArea} />} />
                        <Route path="/create_room_type/:projectId" element={<PrivateRoute Component={CreateRoomType} />} />
                        <Route path="/edit_building_info/:pk/:projectId" element={<PrivateRoute Component={EditBuildingInfo} />} />
                        <Route path="/edit_building_name/:pk/:projectId" element={<PrivateRoute Component={EditBuildingName} />} />
                        <Route path="/delete_building_name/:pk/:projectId" element={<PrivateRoute Component={DeleteBuildingName} />} />
                        <Route path="/edit_maintenance_cost/:pk/:projectId" element={<PrivateRoute Component={EditMaintenanceCost} />} />
                        <Route path="/facility_area/:projectId" element={<PrivateRoute Component={FacilityArea} />} />
                        <Route path="/maintenance_cost_summary/:projectId" element={<PrivateRoute Component={MaintenanceCostSum} />} />
                        <Route path="/project/:projectId" element={<PrivateRoute Component={ProjectDetail} />} />
                        <Route path="/project_list" element={<PrivateRoute Component={ProjectList} />} />
                        <Route path="/project_summary/:projectId" element={<PrivateRoute Component={ProjectSummary} />} />
                        <Route path="/project_totals/:projectId" element={<PrivateRoute Component={ProjectTotals} />} />
                        <Route path="/room_type_list/:projectId" element={<PrivateRoute Component={RoomTypeList} />} />
                        <Route path="/add_maintenance_cost/:projectId" element={<PrivateRoute Component={AddMaintenanceCost} />} />
                        <Route path="/pls_products" element={<PrivateRoute Component={CSVUploader}/>} />
                        <Route path="/margins/:projectId" element={<PrivateRoute Component={Margins}/>} />
                        <Route path="/add_margins/:projectId" element={<PrivateRoute Component={AddMargins}/>} />
                        <Route path="/edit_margins/:projectId" element={<PrivateRoute Component={EditMargins}/>} />

                    </Routes>
                </div>
            </Router>
        </AuthProvider>

        </>

    );
}
export default App;