import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../css/ProjectList.css';
import ProjectDetail from './ProjectDetails';

function ProjectList() {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    // https://light.quant.ac/api
    //${process.env.REACT_APP_API_ENDPOINT}
    useEffect(() => {
        const fetchProjectsFromDjango = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/projects/`);
                console.log(response);

                if (Array.isArray(response.data)) {
                    // Response is an array of projects
                    setProjects(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching project list:', error);
                setError('Error fetching project list');
            }
        };

        fetchProjectsFromDjango();
    }, []);

    return (
        <div className="project-list">
            <h1 className="project-list-title">Project List</h1>
            {error ? (
                <p className="error-message">{error}</p>
            ) : (
                <ul className="project-details" style={{ listStyleType: 'none', padding: 0 }}>
                    {projects.map(project => (
                        <li key={project.id} className="project-item">
                            <Link to={`/project/${project.id}`} className="project-details" state={{ projectName: project.name }}>
                                {project.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default ProjectList;
