import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate  } from 'react-router-dom';

const AddMaintenanceCost = () => {
    const [cost, setCost] = useState('');
    const { projectId, pk} = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [totalNoLightFittingsExisting, setTotalNoLightFittingsExisting] = useState(0);
    const [totalNoLightFittingsProposed, setTotalNoLightFittingsProposed] = useState(0);
    const [failureRateLightsExisting, setFailureRateLightsExisting] = useState(10.0);
    const [failureRateLightsProposed, setFailureRateLightsProposed] = useState(2.5);
    const [costPerLightExisting, setCostPerLightExisting] = useState(15.00);
    const [costPerLightProposed, setCostPerLightProposed] = useState(15.00);
    const [failureRateBallastExisting, setFailureRateBallastExisting] = useState(10.0);
    const [failureRateBallastProposed, setFailureRateBallastProposed] = useState(1.0);
    const [costPerBallastExisting, setCostPerBallastExisting] = useState(35.00);
    const [costPerBallastProposed, setCostPerBallastProposed] = useState(30.00);
    const [electricianHoursExisting, setElectricianHoursExisting] = useState(40);
    const [electricianHoursProposed, setElectricianHoursProposed] = useState(10);
    const [costPerElectricianHourExisting, setCostPerElectricianHourExisting] = useState(35.00);
    const [costPerElectricianHourProposed, setCostPerElectricianHourProposed] = useState(35.00);
    const [totalCostLightsExisting, setTotalCostLightsExisting] = useState(0);
    const [totalCostLightsProposed, setTotalCostLightsProposed] = useState(0);
    const [totalCostBallastExisting, setTotalCostBallastExisting] = useState(0);
    const [totalCostBallastProposed, setTotalCostBallastProposed] = useState(0);
    const [totalCostElectricianExisting, setTotalCostElectricianExisting] = useState(0);
    const [totalCostElectricianProposed, setTotalCostElectricianProposed] = useState(0);
    const [totalMaintenanceCostExisting, setTotalMaintenanceCostExisting] = useState(0);
    const [totalMaintenanceCostProposed, setTotalMaintenanceCostProposed] = useState(0);
    const [buildingTotals, setBuildingTotals] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBuildingTotals = async () => {
            try {
                const response = await axios.get(`https://light.quant.ac/api/building_names_totals/${projectId}`);
                if (response.data) {
                    // Response contains a single building object
                    setBuildingTotals(response.data);
                } else {
                    setError('Invalid API response format');
                }
            } catch (error) {
                console.error('Error fetching building info:', error);
                setError('Error fetching building info');
            }
        };

        fetchBuildingTotals();
    }, [projectId]);

    const { totals_light_fittings_existing, totals_light_fittings_proposed } = buildingTotals;

// Now you can use these properties as needed in your component
console.log('totals_light_fittings_existing:', totals_light_fittings_existing);
console.log('totals_light_fittings_proposed:', totals_light_fittings_proposed);

useEffect(() => {
    setTotalNoLightFittingsExisting(totals_light_fittings_existing);
    setTotalNoLightFittingsProposed(totals_light_fittings_proposed);
}, [totals_light_fittings_existing, totals_light_fittings_proposed]);
console.log('Total No Light Fittings Existing:', totalNoLightFittingsExisting);
console.log('Total No Light Fittings Proposed:', totalNoLightFittingsProposed);
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Log the form data before sending the request
        console.log('Form data:', { totalNoLightFittingsExisting, totalNoLightFittingsProposed, failureRateLightsExisting });

        try {
            const response = await axios.post(`https://light.quant.ac/api/maintenance_cost/${projectId}/`,null, {params: {
                project: projectId,
                total_no_light_fittings_existing: parseInt(totalNoLightFittingsExisting),
                total_no_light_fittings_proposed: parseInt(totalNoLightFittingsProposed),
                failure_rate_lights_existing: parseFloat(failureRateLightsExisting),
                failure_rate_lights_proposed: parseFloat(failureRateLightsProposed),
                cost_per_light_existing: parseFloat(costPerLightExisting),
                cost_per_light_proposed: parseFloat(costPerLightProposed),
                failure_rate_ballast_existing: parseFloat(failureRateBallastExisting),
                failure_rate_ballast_proposed: parseFloat(failureRateBallastProposed),
                cost_per_ballast_existing: parseFloat(costPerBallastExisting),
                cost_per_ballast_proposed: parseFloat(costPerBallastProposed),
                electrician_hours_existing: parseInt(electricianHoursExisting),
                electrician_hours_proposed: parseInt(electricianHoursProposed),
                cost_per_electrician_hour_existing: parseFloat(costPerElectricianHourExisting),
                cost_per_electrician_hour_proposed: parseFloat(costPerElectricianHourProposed),
                total_cost_lights_existing: parseFloat(totalCostLightsExisting),
                total_cost_lights_proposed: parseFloat(totalCostLightsProposed),
                total_cost_ballast_existing: parseFloat(totalCostBallastExisting),
                total_cost_ballast_proposed: parseFloat(totalCostBallastProposed),
                total_cost_electrician_existing: parseFloat(totalCostElectricianExisting),
                total_cost_electrician_proposed: parseFloat(totalCostElectricianProposed),
                total_maintenance_cost_existing: parseFloat(totalMaintenanceCostExisting),
                total_maintenance_cost_proposed: parseFloat(totalMaintenanceCostProposed),


           }});

            if (response && response.data) {
                console.log(response.data); // Log the response data
                setSuccess(true); // Set success state only if submission was successful
                navigate(`/project/${projectId}`);
            } else {
                console.error('Invalid response:', response);
                setErrors({ name: 'Invalid response from the server' });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            setErrors({ name: error.response ? error.response.data.error : error.message });
        }
    };

    useEffect(() => {
        setTotalCostLightsExisting((totalNoLightFittingsExisting * (failureRateLightsExisting/100) *costPerLightExisting) );
    }, [totalNoLightFittingsExisting,failureRateLightsExisting, costPerLightExisting]);

    useEffect(() => {
        setTotalCostLightsProposed((totalNoLightFittingsProposed * (failureRateLightsProposed/100) *costPerLightProposed) );
    }, [totalNoLightFittingsProposed,failureRateLightsProposed, costPerLightProposed]);

    useEffect(() => {
        setTotalCostBallastExisting((totalNoLightFittingsExisting * (failureRateBallastExisting/100) *costPerBallastExisting) );
    }, [totalNoLightFittingsExisting,failureRateBallastExisting, costPerBallastExisting]);

    useEffect(() => {
        const totalCostBallastProposeda =( (totalNoLightFittingsProposed * (failureRateBallastProposed/100) *costPerBallastProposed)).toFixed(2);
        setTotalCostBallastProposed(totalCostBallastProposeda);

    }, [totalNoLightFittingsProposed,failureRateBallastProposed, costPerBallastProposed]);

    useEffect(() => {
        setTotalCostElectricianExisting((electricianHoursExisting * costPerElectricianHourExisting) );
    }, [electricianHoursExisting,costPerElectricianHourExisting]);
    

    useEffect(() => {
        setTotalCostElectricianProposed((electricianHoursProposed * costPerElectricianHourProposed ) );
    }, [electricianHoursProposed,costPerElectricianHourProposed]);

    useEffect(() => {
        setTotalMaintenanceCostExisting((totalCostLightsExisting + totalCostBallastExisting +totalCostElectricianExisting ) );
    }, [totalCostLightsExisting,totalCostBallastExisting , totalCostElectricianExisting]);

    useEffect(() => {
        setTotalMaintenanceCostProposed((totalCostLightsProposed + totalCostBallastProposed+ totalCostElectricianProposed ) );
    }, [totalCostLightsProposed,totalCostBallastProposed,totalCostElectricianProposed]);

    
    

    


    return (
        <div>
            <h1>Add Maintenance Cost</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Total No Light Fittings Existing:
                    <input
                        type="number"
                        value={totalNoLightFittingsExisting}
                        onChange={(e) => setTotalNoLightFittingsExisting(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total No Light Fittings Proposed:
                    <input
                        type="number"
                        value={totalNoLightFittingsProposed}
                        onChange={(e) => setTotalNoLightFittingsProposed(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Failure Rate Lights Existing:
                    <input
                        type="number"
                        value={failureRateLightsExisting}
                        onChange={(e) => setFailureRateLightsExisting(e.target.value)}
                    />
                </label>
                <label>
                    Failure Rate Lights Proposed:
                    <input
                        type="number"
                        value={failureRateLightsProposed}
                        onChange={(e) => setFailureRateLightsProposed(e.target.value)}
                    />
                </label>
                <label>
                    Cost Per Light Existing:
                    <input
                        type="number"
                        value={costPerLightExisting}
                        onChange={(e) => setCostPerLightExisting(e.target.value)}
                    />
                </label>
                <label>
                    Cost Per Light Proposed:
                    <input
                        type="number"
                        value={costPerLightProposed}
                        onChange={(e) => setCostPerLightProposed(e.target.value)}
                    />
                </label>
                <label>
                    Failure Rate Ballast Existing:
                    <input
                        type="number"
                        value={failureRateBallastExisting}
                        onChange={(e) => setFailureRateBallastExisting(e.target.value)}
                    />
                </label>
                <label>
                    Failure Rate Ballast Proposed:
                    <input
                        type="number"
                        value={failureRateBallastProposed}
                        onChange={(e) => setFailureRateBallastProposed(e.target.value)}
                    />
                </label>
                <label>
                    Cost Per Ballast Existing:
                    <input
                        type="number"
                        value={costPerBallastExisting}
                        onChange={(e) => setCostPerBallastExisting(e.target.value)}
                    />
                </label>
                <label>
                    Cost Per Ballast Proposed:
                    <input
                        type="number"
                        value={costPerBallastProposed}
                        onChange={(e) => setCostPerBallastProposed(e.target.value)}
                    />
                </label>
                <label>
                    Electrician Hours Existing:
                    <input
                        type="number"
                        value={electricianHoursExisting}
                        onChange={(e) => setElectricianHoursExisting(e.target.value)}
                    />
                </label>
                <label>
                    Electrician Hours Proposed:
                    <input
                        type="number"
                        value={electricianHoursProposed}
                        onChange={(e) => setElectricianHoursProposed(e.target.value)}
                    />
                </label>
                <label>
                    Cost Per Electrician Hour Existing:
                    <input
                        type="number"
                        value={costPerElectricianHourExisting}
                        onChange={(e) => setCostPerElectricianHourExisting(e.target.value)}
                    />
                </label>
                <label>
                    Cost Per Electrician Hour Proposed:
                    <input
                        type="number"
                        value={costPerElectricianHourProposed}
                        onChange={(e) => setCostPerElectricianHourProposed(e.target.value)}
                    />
                </label>
                <label>
                    Total Cost Lights Existing:
                    <input
                        type="number"
                        value={totalCostLightsExisting}
                        onChange={(e) => setTotalCostLightsExisting(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total Cost Lights Proposed:
                    <input
                        type="number"
                        value={totalCostLightsProposed}
                        onChange={(e) => setTotalCostLightsProposed(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total Cost Ballast Existing:
                    <input
                        type="number"
                        value={totalCostBallastExisting}
                        onChange={(e) => setTotalCostBallastExisting(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total Cost Ballast Proposed:
                    <input
                        type="number"
                        value={totalCostBallastProposed}
                        onChange={(e) => setTotalCostBallastProposed(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total Cost Electrician Existing:
                    <input
                        type="number"
                        value={totalCostElectricianExisting}
                        onChange={(e) => setTotalCostElectricianExisting(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total Cost Electrician Proposed:
                    <input
                        type="number"
                        value={totalCostElectricianProposed}
                        onChange={(e) => setTotalCostElectricianProposed(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total Maintenance Cost Existing:
                    <input
                        type="number"
                        value={totalMaintenanceCostExisting}
                        onChange={(e) => setTotalMaintenanceCostExisting(e.target.value)}
                        readOnly
                    />
                </label>
                <label>
                    Total Maintenance Cost Proposed:
                    <input
                        type="number"
                        value={totalMaintenanceCostProposed}
                        onChange={(e) => setTotalMaintenanceCostProposed(e.target.value)}
                        readOnly
                    />
                </label>
                <button type="submit">Add Cost</button>
            </form>
            {success && <p>Cost added successfully!</p>}
            {errors && <p>Error: {errors.name}</p>}
        </div>
    );
};

export default AddMaintenanceCost;