import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './AuthContext';

const Logout = () => {
  const { logout: handleLogout, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  // If the user is not logged in, there is no need to show the logout button
  if (!isLoggedIn) {
    return null;
  }

  const handleLogoutClick = () => {
    handleLogout();
    navigate(`/project_list/`);
  };

  return (
    <div>
      <br></br>
      <br></br>
      <br></br><br></br>
      <br></br>
      <br></br>
      <br></br>
      <button className="btn btn-nav rounded-pill me-2" onClick={handleLogoutClick}>
        Logout
      </button>
    </div>
  );
};

export default Logout;